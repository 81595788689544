import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },

  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  divContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: '1rem'
  },
  form: {
    width: '100%'
  }
}));

const prioOptions = [
  { value: 'disable', label: 'Disable', id: 0 },
  { value: 'low', label: 'Low', id: 1 },
  { value: 'high', label: 'High', id: 2 },
  { value: 'panic', label: 'Panic', id: 3 }
];

const sourceOptions = [
  { value: 'GPS', label: 'GPS', id: 0 },
  { value: 'Accelerometer', label: 'Accelerometer', id: 1 }
];

const GreenDriving = ({ greenDrivingRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    control,

    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      duration: greenDrivingRecord[0]?.current?.duration,
      max_accel: greenDrivingRecord[0]?.current?.max_accel,
      max_angle: greenDrivingRecord[0]?.current?.max_angle,
      max_brake: greenDrivingRecord[0]?.current?.max_brake || 0,
      prio: greenDrivingRecord[0]?.current?.prio,
      source: greenDrivingRecord[0]?.current?.source
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGreenDrivingSubmit = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log(data);
    setLoading(true);

    let greenDrivingPayload = {
      deviceId: greenDrivingRecord[0]?.device_id,
      settSelector: 'green_driving_obd',
      properties: {
        duration: data.duration,
        max_accel: data.max_accel,
        max_angle: data.max_angle,
        max_brake: data.max_brake || 0,
        prio: data.prio,
        source: data.source
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(greenDrivingPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated green driving settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update green driving settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.divContainer}>
      <form onSubmit={handleSubmit(handleGreenDrivingSubmit)} className={classes.form}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Green Driving</Typography>
                <Typography className={classes.secondaryHeading}>Green driving scenario settings</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Green Driving</Typography>

            {/* prio */}
            <div style={{ marginBottom: '1rem' }}>
              <Controller
                name="prio"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={prioOptions}
                    isSearchable
                    value={prioOptions?.find((option) => option.id === field.value)}
                    onChange={(selected) => field.onChange(selected ? selected.id : null)}
                    classNamePrefix="select"
                    name="prio"
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
              {errors.prio && (
                <Typography color="error" style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>
                  {errors.prio.message}
                </Typography>
              )}
            </div>

            {/* max_accel */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="max_accel"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Max Acceleration"
                    name="max_accel"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.max_accel}
                    helperText={errors.max_accel?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m/sec^2</InputAdornment>
                    }}
                  />
                )}
              />
            </div>

            {/* max_brake */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="max_brake"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Max Breaking"
                    name="max_brake"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.max_brake}
                    helperText={errors.max_brake?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m/sec^2</InputAdornment>
                    }}
                  />
                )}
              />
            </div>

            {/* max_angle */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="max_angle"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Max Angle"
                    name="max_angle"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.max_angle}
                    helperText={errors.max_angle?.message}
                  />
                )}
              />
            </div>

            {/* source */}
            <div style={{ marginBottom: '1rem' }}>
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={sourceOptions}
                    isSearchable
                    value={sourceOptions?.find((option) => option.id === field.value)}
                    onChange={(selected) => field.onChange(selected ? selected.id : null)}
                    classNamePrefix="select"
                    name="source"
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
              {errors.source && (
                <Typography color="error" style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>
                  {errors.source.message}
                </Typography>
              )}
            </div>

            {/* duration */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                control={control}
                name="duration"
                render={({ field }) => (
                  <Switch
                    name="duration"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default GreenDriving;

GreenDriving.propTypes = {
  greenDrivingRecord: PropTypes.any
};
