import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  detailWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px'
  },
  secondaryText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  detailItem: {
    display: 'flex',
    flex: '1'
  }
}));

const ListItemDetails = ({ record }) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <ListItemText
          primary={record.deviceName}
          secondary={
            <Typography component="div" className={classes.secondaryText}>
              <div className={classes.detailWrapper}>
                <div className={classes.detailItem}>Coordinates:</div>
                <div className={classes.detailItem}>{`${record.lat}, ${record.long}`}</div>
              </div>
              <div className={classes.detailWrapper}>
                <div className={classes.detailItem}>Location:</div>

                <div className={classes.detailItem}>{record?.location || null}</div>
              </div>
            </Typography>
          }
        />
      </div>
    </div>
  );
};

export default ListItemDetails;

ListItemDetails.propTypes = {
  record: PropTypes.shape({
    deviceName: PropTypes.string,
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    timestamp: PropTypes.number,
    engineIgnitionStatus: PropTypes.bool,
    vehicleMileage: PropTypes.number,
    sleepModeEnum: PropTypes.number,
    protocolId: PropTypes.number,
    positionAltitude: PropTypes.number,
    positionDirection: PropTypes.number,
    positionSpeed: PropTypes.number,
    gsmSignalLevel: PropTypes.number, // number but its the percentage
    batteryCurrent: PropTypes.number, //its in volt
    batteryVoltage: PropTypes.number,
    externalPowersourceVoltage: PropTypes.number,
    deviceId: PropTypes.any,
    location: PropTypes.string
  })
};
