import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },

  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  divContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: '1rem'
  },
  form: {
    width: '100%'
  }
}));

const IgnitionOnCounter = ({ ignitionOnCounterRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      enable: ignitionOnCounterRecord[0]?.current?.enable,
      counter_value: ignitionOnCounterRecord[0]?.current?.counter_value
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGreenDrivingSubmit = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log(data);
    setLoading(true);

    let greenDrivingPayload = {
      deviceId: ignitionOnCounterRecord[0]?.device_id,
      settSelector: 'ignition_on_counter',
      properties: {
        enable: data.enable,
        counter_value: data.counter_value
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(greenDrivingPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated ignition on counter settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update ignition on counter settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.divContainer}>
      <form onSubmit={handleSubmit(handleGreenDrivingSubmit)} className={classes.form}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Ignition ON Counter</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Ignition ON Counter</Typography>

            {/* enable */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                control={control}
                name="enable"
                render={({ field }) => (
                  <Switch
                    name="enable"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                  />
                )}
              />
            </div>

            {/* counter_value */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="counter_value"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Counter Value"
                    name="counter_value"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.counter_value}
                    helperText={errors.counter_value?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                    }}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default IgnitionOnCounter;

IgnitionOnCounter.propTypes = {
  ignitionOnCounterRecord: PropTypes.any
};
