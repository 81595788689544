import React, { useEffect, useState } from 'react';
import billService from '../../../services/BillService';
import { getFormattedEmployeeList } from '../../UserNew/helper/helper';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';
import employeeService from '../../../services/EmployeeService';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  successIcon: { color: '#388e3c' },
  errorIcon: { color: '#dc004e' },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  }
}));

const TimesheetBillLogs = () => {
  const classes = useStyles();

  const [billLogRecords, setBillLogRecords] = useState([]);
  const [billPage, setBillPage] = useState(0);
  const [billPerPage, setBillRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchTimesheetBillLogs = async () => {
      const billLogResponse = await billService.getBillLogs();
      const billLogRecords = billLogResponse?.data?.data;
      // eslint-disable-next-line no-console, no-undef
      console.log('billLogRecords', billLogRecords);

      if (billLogRecords) setBillLogRecords(billLogRecords);
    };
    fetchTimesheetBillLogs();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeeRecords = await employeeService.getEmployees();

      const formattedEmployeeList = getFormattedEmployeeList(employeeRecords.data.data);
      // eslint-disable-next-line no-console, no-undef
      console.log('formattedEmployeeList', formattedEmployeeList);
    };
    fetchEmployees();
  }, []);

  const handleChangePage = (event, newPage) => {
    setBillPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setBillRowsPerPage(parseInt(event.target.value, 10));
    setBillPage(0);
  };

  // Slice data for current page
  const paginatedRecords = billLogRecords?.slice(billPage * billPerPage, billPage * billPerPage + billPerPage);

  return (
    <div className={classes.root}>
      {paginatedRecords.length > 0 ? (
        <List>
          {paginatedRecords.map((log) => (
            <React.Fragment key={log._id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ListAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`Bill generated by ${log.createdBy.adminDisplayName} on ${format(
                    new Date(log.createdAt),
                    'dd/MM/yyyy'
                  )}`}
                  secondary={
                    <Typography component="div">
                      <Typography variant="body2">
                        <b>{log.comment}</b>
                      </Typography>
                      {log.billFailedUserDetail.length > 0 &&
                        log.billFailedUserDetail.map((bill) => (
                          <div key={bill.employeeId}>
                            <Typography variant="body2">
                              <b>{bill.employeeDisplayName} error:</b>
                            </Typography>
                            <ul>
                              {bill.error.Fault.Error.map((errorItem, index) => (
                                <li key={index}>{errorItem.Detail}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                    </Typography>
                  }
                />
                {log.billFailedUserDetail.length > 0 ? (
                  <ErrorIcon className={classes.errorIcon} />
                ) : (
                  <CheckBoxIcon className={classes.successIcon} />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography align="center">No logs to display</Typography>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={billLogRecords.length}
        page={billPage}
        onPageChange={handleChangePage}
        rowsPerPage={billPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TimesheetBillLogs;
