import React from 'react';
import PropTypes from 'prop-types';

import { Marker, Popup, Tooltip } from 'react-leaflet';
import { LeafletTrackingMarker } from 'react-leaflet-tracking-marker';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { isLessThanAWeek, withInTenMinutes } from '../helper/helper';
import { carIcon, getMarkerIcon } from './MarkerIcon';
import ReactDOMServer from 'react-dom/server';
import NavigationIcon from '@material-ui/icons/Navigation';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import LocationOnIcon from '@material-ui/icons/LocationOn';

// Create a rotating marker based on direction
const getRotatingMarkerIcon = (mapIcon, color) => {
  if (mapIcon === 'bluetooth') {
    const iconHTML = ReactDOMServer.renderToString(
      <BluetoothIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
  if (mapIcon === 'vehicle') {
    // Define filters for each color
    const filters = {
      red: 'invert(8%) sepia(99%) saturate(7404%) hue-rotate(4deg) brightness(95%) contrast(118%)',
      green: 'invert(24%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)',
      orange: 'invert(61%) sepia(97%) saturate(5000%) hue-rotate(15deg) brightness(108%) contrast(100%)'
    };

    // Choose the filter based on the color
    const filter = filters[color] || ''; // Default to no filter if color is not defined

    const iconHTML = `
      <div style="width: 32px; height: 32px; display: flex; align-items: center; justify-content: center;">
        <img src="/car.png" style="width: 100%; height: 100%; filter: ${filter};" alt="vehicle-icon" />
      </div>
    `;

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [32, 32], // Adjust size as needed
      iconAnchor: [16, 16] // Center the icon
    });
  }

  if (mapIcon === 'location') {
    const iconHTML = ReactDOMServer.renderToString(
      <LocationOnIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
  if (mapIcon === 'navigation') {
    const iconHTML = ReactDOMServer.renderToString(
      <NavigationIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
};

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapVehicleItem = ({ vehicleTrackerItem, handleClickedMapItem }) => {
  const fallbackLatitude = -37.79869;
  const fallbackLongitude = 144.89608;

  // Default to fallback if lat or long is missing or invalid
  const latitude = vehicleTrackerItem.lat || fallbackLatitude;
  const longitude = vehicleTrackerItem.long || fallbackLongitude;

  // eslint-disable-next-line no-undef
  const retrievedLiveData = JSON.parse(sessionStorage.getItem(`deviceData-${vehicleTrackerItem?.deviceName}`));

  const liveDataPositions = [];
  retrievedLiveData?.forEach((liveData) => {
    if (liveData?.latitude && liveData?.longitude) {
      liveDataPositions.push([liveData.latitude, liveData.longitude]);
    }
  });

  const firstPosition = liveDataPositions[0] || [latitude, longitude];
  const secondPosition = liveDataPositions[1] || [latitude, longitude];

  return (
    <div>
      {vehicleTrackerItem?.engineIgnitionStatus && retrievedLiveData?.length > 0 ? (
        <LeafletTrackingMarker
          key={`${liveDataPositions[0]?.latitude}-${liveDataPositions[0]?.longitude}`}
          icon={carIcon}
          position={firstPosition}
          previousPosition={secondPosition}
          duration={1000}
          keepAtCenter={false}
        >
          <Popup>{vehicleTrackerItem.deviceName}</Popup>
        </LeafletTrackingMarker>
      ) : (
        <Marker
          key={vehicleTrackerItem.deviceName || vehicleTrackerItem.deviceId}
          position={[latitude, longitude]}
          icon={determineMarkerIcon(vehicleTrackerItem)}
          // icon={carIcon}
          eventHandlers={{
            click: () =>
              handleClickedMapItem(
                vehicleTrackerItem.deviceName,
                vehicleTrackerItem.deviceId,
                vehicleTrackerItem.beaconMac
              )
          }}
        >
          <Popup>
            {vehicleTrackerItem.deviceName} <br />
          </Popup>
          <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
            <span>{vehicleTrackerItem.deviceName}</span>
          </Tooltip>
        </Marker>
      )}
    </div>
  );
};

export default MapVehicleItem;

MapVehicleItem.propTypes = {
  vehicleTrackerItem: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    deviceName: PropTypes.string,
    timestamp: PropTypes.number,
    positionDirection: PropTypes.any,
    engineIgnitionStatus: PropTypes.bool,
    mapIcon: PropTypes.string,
    beaconMac: PropTypes.any,
    deviceId: PropTypes.any
  }),
  handleClickedMapItem: PropTypes.func
};

const determineMarkerIcon = (vehicleTrackerItem) => {
  const { timestamp, mapIcon } = vehicleTrackerItem;

  if (withInTenMinutes(timestamp)) {
    return mapIcon ? getRotatingMarkerIcon(mapIcon, 'green') : getMarkerIcon('green');
  }
  if (isLessThanAWeek(timestamp)) {
    return mapIcon ? getRotatingMarkerIcon(mapIcon, 'orange') : getMarkerIcon('orange');
  }
  return mapIcon ? getRotatingMarkerIcon(mapIcon, 'red') : getMarkerIcon('red');
};
