import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    marginBottom: '1rem',
    display: 'flex'
  }
}));

const GPRSSetup = ({ gprsSetupRecord }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      apn: gprsSetupRecord[0]?.current?.apn,
      enabled: gprsSetupRecord[0]?.current?.enabled,
      password: gprsSetupRecord[0]?.current?.password,
      username: gprsSetupRecord[0]?.current?.username
    }
  });

  const handleGPRSSetup = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('data', data);
    setLoading(true);

    const gprsSetupPayload = {
      deviceId: gprsSetupRecord[0]?.device_id,
      settSelector: 'gprs',
      properties: {
        apn: data.apn,
        enabled: data.enabled,
        password: data.password,
        username: data.username
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('gprsSetupPayload', gprsSetupPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(gprsSetupPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated gprs setup setting');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update gprs setup settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors are,', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleGPRSSetup)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>GPRS Setup</Typography>
                <Typography className={classes.secondaryHeading}>
                  Setup sending data by GPRS link. Note: after changing this setting device will be disconnected from
                  current server.
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>GPRS Setup</Typography>

            <div className={classes.accordionDetailsItem}>
              <Controller
                control={control}
                name="enabled"
                render={({ field }) => (
                  <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />
                )}
              />
              <label style={{ marginTop: '8px' }}>GPRS Enabeld</label>
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="apn"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="APN Name"
                    name="apn"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.apn}
                    helperText={errors.apn?.message}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="APN Username"
                    name="username"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.username}
                    helperText={errors.username?.message}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="APN Password"
                    name="password"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default GPRSSetup;

GPRSSetup.propTypes = {
  gprsSetupRecord: PropTypes.array
};
