import React from 'react';
import PropTypes from 'prop-types';

import GreenDriving from './GreenDriving';

import { makeStyles } from '@material-ui/core';
import IgnitionOnCounter from './IgnitionOnCounter';
import OverSpeeding from './OverSpeeding';

const useStyles = makeStyles((theme) => ({
  miscellaneousTab: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  }
}));

const MiscellaneousTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.miscellaneousTab}>
      <GreenDriving greenDrivingRecord={selectedTabSettings.filter((record) => record.name === 'green_driving_obd')} />
      <OverSpeeding overSpeedingRecord={selectedTabSettings.filter((record) => record.name === 'overspeeding_obd')} />
      <IgnitionOnCounter
        ignitionOnCounterRecord={selectedTabSettings.filter((record) => record.name === 'ignition_on_counter')}
      />
    </div>
  );
};

export default MiscellaneousTab;

MiscellaneousTab.propTypes = {
  selectedTabSettings: PropTypes.any
};
