import React, { useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const MotionDetectionSource = ({ motionDetectionRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [state] = useState({
    ignition: motionDetectionRecord[0]?.current?.ignition || false,
    movement: motionDetectionRecord[0]?.current?.movement || false,
    gps: motionDetectionRecord[0]?.current?.gps || false,
    can: motionDetectionRecord[0]?.current?.can || false
  });

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({ defaultValues: state });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMotionDetectionChanges = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log(data);
    setLoading(true);

    const motionDetectionPayload = {
      deviceId: motionDetectionRecord[0]?.device_id,
      settSelector: 'motion_detection',
      properties: {
        ignition: data.ignition,
        movement: data.movement,
        gps: data.gps,
        can: data.can
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(motionDetectionPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated motion detection source');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleMotionDetectionChanges)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Motion Detection Source</Typography>
                <Typography className={classes.secondaryHeading}>
                  Object motion detection is to be configured to determine device working mode. Other functionalities
                  that depend on movement source are: power manager, fuel consumption and trip.
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e ', marginBottom: '10px' }}>Motion Detection Source</Typography>

            <div className={classes.accordionDetailsItem}>
              <label>Ignition</label>
              <Controller
                control={control}
                name="ignition"
                render={({ field }) => (
                  <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>Movement</label>
              <Controller
                control={control}
                name="movement"
                render={({ field }) => (
                  <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>GPS</label>
              <Controller
                control={control}
                name="gps"
                render={({ field }) => (
                  <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>CAN </label>
              <Controller
                control={control}
                name="can"
                render={({ field }) => (
                  <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />
                )}
              />
            </div>
          </AccordionDetails>
          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default MotionDetectionSource;

MotionDetectionSource.propTypes = {
  motionDetectionRecord: PropTypes.any
};
