import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: '1rem'
  }
}));

const TrackingOnDemandTiming = ({ trackingOnDemandTimingRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      duration: trackingOnDemandTimingRecord[0]?.current?.duration,
      period: trackingOnDemandTimingRecord[0]?.current?.period
    }
  });

  const handleTrackingOnDemandTiming = (data) => {
    setLoading(true);

    const trackingOnDemandTimingPayload = {
      deviceId: trackingOnDemandTimingRecord[0]?.device_id,
      settSelector: 'on_demand_params',
      properties: {
        duration: data.duration,
        period: data.period
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('trackingOnDemandTimingPayload', trackingOnDemandTimingPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(trackingOnDemandTimingPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated tracking on demand timing settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update tracking on demand timing');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleTrackingOnDemandTiming)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Tracking on demand timing</Typography>
                <Typography className={classes.secondaryHeading}>
                  Tracking on demand: period and duration parameters.
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Tracking on demand timing</Typography>
            <div className={classes.accordionDetailsItem}>
              <Controller
                control={control}
                name="duration"
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="duration"
                    name="duration"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                    label="Tracking on demand timing"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> seconds</InputAdornment>
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="period"
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="period"
                    name="period"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                    label="Tracking duration"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> seconds</InputAdornment>
                    }}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={Object.keys(errors).length > 0 || loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default TrackingOnDemandTiming;

TrackingOnDemandTiming.propTypes = {
  trackingOnDemandTimingRecord: PropTypes.array
};
