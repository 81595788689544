import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    marginBottom: '1rem'
  }
}));

const recordsParametersOptions = [
  { value: 'newestFirst', label: 'Newest First', id: 0 },
  { value: 'oldestFirst', label: 'Oldest First', id: 1 }
];

const RecordsParameters = ({ recordsParamsRecord }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      online: recordsParamsRecord[0]?.current?.online,
      order: recordsParamsRecord[0]?.current?.order,
      response: recordsParamsRecord[0]?.current?.response
    }
  });

  const handleRecordsParams = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('data', data);
    setLoading(true);

    const recordsParamsPayload = {
      deviceId: recordsParamsRecord[0]?.device_id,
      settSelector: 'records_params',
      properties: {
        online: data.online,
        order: data.order,
        response: data.response
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('recordsParamsPayload', recordsParamsPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(recordsParamsPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated records parameters setting');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update records parameters settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors are,', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleRecordsParams)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Records Params</Typography>
                <Typography className={classes.secondaryHeading}>Records sending parameters setup</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Records sending parameters setup</Typography>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="online"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Open Link Timeout"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.online}
                    helperText={errors.online?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Seconds</InputAdornment>
                    }}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="response"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Server Response Timeout"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.response}
                    helperText={errors.response?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Seconds</InputAdornment>
                    }}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                label="Records Storing Order"
                control={control}
                name="order"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={recordsParametersOptions.find((option) => option.id === field.value)}
                    isSearchable={true}
                    name="order"
                    options={recordsParametersOptions}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default RecordsParameters;

RecordsParameters.propTypes = {
  recordsParamsRecord: PropTypes.array
};
