import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';

import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Nav from '../Nav/Nav';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SideNavList from './SideNavList/SideNavList';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'rgb(40, 40, 40)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  appTitle: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    paddingTop: '2rem',
    paddingLeft: '4rem',
    marginBottom: '14px',
    cursor: 'pointer'
  },
  image: {
    display: 'block',
    margin: 'auto',
    backgroundColor: '#FFC107',
    transition: {
      backgroundColor: '300ms'
    },
    width: '50px',
    height: '50px',
    borderRadius: '50%'
  },
  hideAppTitle: {
    display: 'flex',
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
  icon: {
    color: 'white'
  }
}));

function SideNav(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [accessLevel, setAccessLevel] = useState();
  const [role, setRole] = useState();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState('');
  const [expandAsset, setExpandAsset] = useState(false);
  const [expandNestedItem, setExpandNestedItem] = useState({
    asset: false,
    timesheetAndLogs: false
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setAccessLevel(localStorage.getItem('access'));
    // eslint-disable-next-line no-undef
    setRole(localStorage.getItem('role'));
  }, []);

  const handleListItemClick = (value) => {
    setSelectedItem(value);
    handleDrawerToggle();
    console.log('value', value);

    if (value === 'Asset') {
      setExpandNestedItem({ ...expandNestedItem, asset: !expandNestedItem.asset });
    }
    if (value === 'Timesheets and Logs') {
      setExpandNestedItem({ ...expandNestedItem, timesheetAndLogs: !expandNestedItem.timesheetAndLogs });
    }
  };

  const handleLargeDevice = (value) => {
    setSelectedItem(value);

    if (value === 'Asset') {
      setExpandNestedItem({ ...expandNestedItem, asset: !expandNestedItem.asset });
    }
    if (value === 'Timesheets and Logs') {
      setExpandNestedItem({ ...expandNestedItem, timesheetAndLogs: !expandNestedItem.timesheetAndLogs });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAppTitle = () => {
    history.push('/dashboard');
  };

  const drawer = (
    <div style={{ backgroundColor: 'rgb(40, 40, 40)' }}>
      {!mobileOpen && (
        <div className={classes.appTitle} onClick={handleAppTitle}>
          <div>
            <img
              src="boxshall_logo.png"
              alt="website_logo"
              style={{ maxHeight: '34px', height: '100%', maxWidth: '42px', width: '100%', objectFit: 'contain' }}
            />
          </div>
          <div style={{ lineHeight: '1' }}>
            <span style={{ fontSize: '20px', color: '#ecab53' }}>Verd</span>
          </div>
        </div>
      )}
      <div className={classes.toolbar} />
      <Typography style={{ fontSize: '1rem', color: 'white', paddingLeft: '20px', paddingTop: '1rem' }}>
        Menu
      </Typography>
      <List>
        <SideNavList
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          role="User"
          selectedItem={selectedItem}
          handleListItemClick={handleListItemClick}
          handleLargeDevice={handleLargeDevice}
          expandNestedItem={expandNestedItem}
        />
      </List>
      {role && role === 'Admin' && (
        <>
          <Divider style={{ backgroundColor: 'white', marginLeft: '20px', width: '80%' }} />
          <Typography style={{ fontSize: '1rem', color: 'white', paddingLeft: '20px', paddingTop: '1rem' }}>
            Administration
          </Typography>
          <List>
            <SideNavList
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              role="Admin"
              selectedItem={selectedItem}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              expandNestedItem={expandNestedItem}
            />
          </List>
        </>
      )}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Nav handleDrawerToggle={handleDrawerToggle} handleAppTitle={handleAppTitle} />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

SideNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default SideNav;
