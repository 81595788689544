import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const WelcomeBack = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Show the message when the component mounts (user logs in)
    setShowMessage(true);

    // Set a timer to hide the message after 5 seconds
    // eslint-disable-next-line no-undef
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 9000);

    // Cleanup the timer when the component unmounts or state changes
    // eslint-disable-next-line no-undef
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div>
      {showMessage && (
        <Card style={{ margin: '0 2rem 1rem 19px', width: '98%' }}>
          <CardContent>
            <Typography component="div">
              {/* eslint-disable-next-line no-undef */}
              Welcome back <b>{localStorage.getItem('name')}</b>! Let’s make today your most productive yet.
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default WelcomeBack;
