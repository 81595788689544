import React from 'react';
import PropTypes from 'prop-types';

import StaticNavigation from './StaticNavigation';
import MotionDetectionSource from './MotionDetectionSource';
import GNSSSource from './GNSSSource';
import BatteryChargeMode from './BatteryChargeMode';
import SleepMode from './SleepMode';
import IgnitionDetectionSource from './IgnitionDetectionSource';
import NetworkTimeProtocolServer from './NetworkTimeProtocolServer';
import GetverCommandResponse from './GetverCommandResponse';
import AccelerometerCalibration from './AccelerometerCalibration';
import DataCodec from './DataCodec';
import { makeStyles } from '@material-ui/core';
import LedIndication from './LedIndication';

const useStyles = makeStyles((theme) => ({
  systemParamsTab: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  systemParamsTabDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}));

const SystemParamsTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.systemParamsTab}>
      <div className={classes.systemParamsTabDiv}>
        <MotionDetectionSource
          motionDetectionRecord={selectedTabSettings.filter((record) => record.name === 'motion_detection')}
        />
        <SleepMode sleepModeRecord={selectedTabSettings.filter((record) => record.name === 'sleep_mode')} />
        <NetworkTimeProtocolServer
          ntpServerRecord={selectedTabSettings.filter((record) => record.name === 'ntp_server')}
        />
      </div>

      <div className={classes.systemParamsTabDiv}>
        <StaticNavigation
          staticNavigationRecord={selectedTabSettings.filter((record) => record.name === 'static_filter')}
        />
        <GNSSSource gnssSource={selectedTabSettings.filter((record) => record.name === 'gnss_source')} />
        <GetverCommandResponse getverRecord={selectedTabSettings.filter((record) => record.name === 'getver')} />
      </div>

      <div className={classes.systemParamsTabDiv}>
        <LedIndication ledDetectionRecord={selectedTabSettings.filter((record) => record.name === 'led_indication')} />
        <BatteryChargeMode
          batteryChargeModeRecord={selectedTabSettings.filter((record) => record.name === 'battery_charge_mode')}
        />
        <IgnitionDetectionSource
          ignitionDetectionSourceRecord={selectedTabSettings.filter((record) => record.name === 'ignition_detection')}
        />
        <AccelerometerCalibration
          accelerometerCalibrationRecord={selectedTabSettings.filter(
            (record) => record.name === 'accelerometer_gravity'
          )}
        />
        <DataCodec dataCodecRecord={selectedTabSettings.filter((record) => record.name === 'data_codec')} />
      </div>
    </div>
  );
};

export default SystemParamsTab;

SystemParamsTab.propTypes = {
  selectedTabSettings: PropTypes.any
};
