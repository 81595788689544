import PropTypes from 'prop-types';
import React from 'react';
import HomeGSMNetworkSetting from './HomeGSMNetworkSetting';
import TripScenarioParameters from './TripScenarioParameters';
import RoamingGSMNetworkSetting from './RoamingGSMNetworkSetting';
import Odometer from './Odometer';
import TrackingOnDemandMode from './TrackingOnDemandMode';
import UnknownGSMNetworkSetting from './UnknownGSMNetworkSetting';
import TrackingOnDemandTiming from './TrackingOnDemandTiming';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  tabDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}));

const TrackingTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabWrapper}>
      <div className={classes.tabDiv}>
        <HomeGSMNetworkSetting
          homeGSMSettingRecord={selectedTabSettings.filter((record) => record.name === 'home_network')}
        />
        <TripScenarioParameters
          tripScenarioRecord={selectedTabSettings.filter((record) => record.name === 'trip_scenario')}
        />
      </div>

      <div className={classes.tabDiv}>
        <RoamingGSMNetworkSetting
          roamingGSMRecord={selectedTabSettings.filter((record) => record.name === 'roaming_network')}
        />
        <Odometer odometerRecord={selectedTabSettings.filter((record) => record.name === 'odometer_fmb')} />
        <TrackingOnDemandMode
          trackingOnDemandModeRecord={selectedTabSettings.filter((record) => record.name === 'on_demand_mode')}
        />
      </div>

      <div className={classes.tabDiv}>
        <UnknownGSMNetworkSetting
          unknownGSMRecord={selectedTabSettings.filter((record) => record.name === 'unknown_network')}
        />
        <TrackingOnDemandTiming
          trackingOnDemandTimingRecord={selectedTabSettings.filter((record) => record.name === 'on_demand_params')}
        />
      </div>
    </div>
  );
};

export default TrackingTab;

TrackingTab.propTypes = {
  selectedTabSettings: PropTypes.array
};

// configuration

// Tracking on demand mode
// Push device to start to generate high priority records and initiate data sending to server
