import { z } from 'zod';

export function findTabSettingRecords(settingRecords, value) {
  if (value === 0) {
    return settingRecords.filter((record) => record.tab === 'System params');
  }
  if (value === 1) {
    return settingRecords.filter((record) => record.tab === 'Connectivity');
  }
  if (value === 2) {
    return settingRecords.filter((record) => record.tab === 'Configuration');
  }
  if (value === 3) {
    return settingRecords.filter((record) => record.tab === 'Tracking');
  }
  if (value === 4) {
    return settingRecords.filter((record) => record.tab === 'Miscellaneous');
  }
  if (value === 5) {
    return settingRecords.filter((record) => record.tab === 'Accelerometer');
  }
  if (value === 6) {
    return settingRecords.filter((record) => record.tab === 'OBD');
  }
  if (value === 7) {
    return settingRecords.filter((record) => record.tab === 'Can');
  }
  if (value === 8) {
    return settingRecords.filter((record) => record.tab === 'Bluetooth');
  }
  if (value === 9) {
    return settingRecords.filter((record) => record.tab === 'Actions');
  }
}

export const ntpServerSchema = z
  .object({
    resync_period: z.string(),
    ntp_server1: z.string(),
    ntp_server2: z.string()
  })
  .refine((data) => Number(data.resync_period) > 0, {
    message: 'Must be higher than 0',
    path: ['resync_period'] // Path to the invalid field
  });

//TODO: show inputs based on this source/ option
// option : textField
//  1:  source
//  2: source, start , stop
//  3: source, start,stop
//  4: source,  high, low
//  5:  source, "high":0,"low":0,
//  6: source, "high":0,"low":0,"source":6,"start":1,"stop":5
//  7: high":0,"low":0,"source":7,"start":1,"stop":5
//  8: source":8
//  9: source":9
//  10: source":10,"start":1,"stop":5
//  11: source":11,"start":1,"stop":5
//  12: high":0,"low":0,"source":12
//  13: "high":0,"low":0,"source":13
//  14: high":0,"low":0,"source":14,"start":1,"stop":5
//  15: high":0,"low":0,"source":15,"start":1,"stop":5

export function getIgnitionDetectionObject(data) {
  console.log('data source value', data?.source);

  if (!data || typeof data.source === 'undefined') {
    return null; // Return null if data or source is invalid
  }

  const { source, high, low, start, stop } = data;

  // Define the configuration for each source type
  const sourceConfigurations = {
    group1: [1, 8, 9],
    group2: [4, 5, 12, 13],
    group3: [2, 3, 10, 11]
  };

  // Check the source and construct the object accordingly
  if (sourceConfigurations.group1.includes(source)) {
    return { source };
  }

  if (sourceConfigurations.group2.includes(source)) {
    return { source, high, low };
  }

  if (sourceConfigurations.group3.includes(source)) {
    return { source, start, stop };
  }

  // Default case for unexpected source values
  return { source, high, low, start, stop };
}
