import React from 'react';
import RecordsParameters from './RecordsParameters';
import EnableConnectionOverTLS from './EnableConnectionOverTLS';
import FirmwareOverAirWebService from './FirmwareOverAirWebService';
import NetworkPing from './NetworkPing';
import GPRSServerSetup from './GPRSServerSetup';
import GPRSSetup from './GPRSSetup';
import SecondaryGPRSServerSetup from './SecondaryGPRSServerSetup';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  connectivityTabWrapper: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  connectivityTabDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}));

const ConnectivityTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.connectivityTabWrapper}>
      <div className={classes.connectivityTabDiv}>
        <RecordsParameters
          recordsParamsRecord={selectedTabSettings.filter((record) => record.name === 'records_params')}
        />
        <EnableConnectionOverTLS
          enableConnectionRecord={selectedTabSettings.filter((record) => record.name === 'tls')}
        />
        <FirmwareOverAirWebService firmwareRecord={selectedTabSettings.filter((record) => record.name === 'fota')} />
      </div>

      <div className={classes.connectivityTabDiv}>
        <NetworkPing networkPingRecord={selectedTabSettings.filter((record) => record.name === 'network_ping')} />
        <GPRSServerSetup
          gprsServerSetupRecord={selectedTabSettings.filter((record) => record.name === 'gprs_server')}
        />
      </div>

      <div className={classes.connectivityTabDiv}>
        <GPRSSetup gprsSetupRecord={selectedTabSettings.filter((record) => record.name === 'gprs')} />
        <SecondaryGPRSServerSetup
          secondaryGPRSServerSetupRecord={selectedTabSettings.filter((record) => record.name === 'backup_server')}
        />
      </div>
    </div>
  );
};

export default ConnectivityTab;

ConnectivityTab.propTypes = {
  selectedTabSettings: PropTypes.array
};
