import React, { useEffect, useState } from 'react';
import { startOfWeek, lastDayOfWeek, parse, format } from 'date-fns';

import useTimesheetFilter from './hooks/useTimesheetFilter';
import useFetchCustomerRefRecords from '../../MyTime/hooks/useFetchCustomerRefs';
import useFetchClassRefRecords from '../../MyTime/hooks/useFetchClassRefs';
import { timesheetPeriodOptions } from '../../MyTime/filter/MyTimeFilter';
import useFetchAllTimesheetStatus from './hooks/useFetchAllTimesheetStatus';
import TimesheetStatus from './header/TimesheetStatus';
import TimesheetFilter from './filter/TimesheetFilter';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonMenu from './header/ButtonMenu';
import timesheetService from '../../../services/TimesheetService';
import { Toaster, toast } from 'react-hot-toast';
import billService from '../../../services/BillService';
import AlertDialog from './dialogbox/Dialogbox';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { isSelectedTimeSheetsValid, validateDateDifference } from './helper/helper';
import useFetchEmployeeRefRecords from './hooks/useFetchEmployeeRefs';
import AdminTimesheetTable from './table/AdminTimesheetTable';

const useStyles = makeStyles(() => ({
  filterButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '2rem',
    cursor: 'pointer'
  },
  buttonWrapper: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  approveButton: {
    borderRadius: ' 12px 0 0 12px',
    borderRight: '1px solid white'
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  searchContainer: {
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
    float: 'right',
    paddingLeft: '20px',
    marginLeft: '1rem'
  },
  resetButton: {
    marginLeft: '1rem',
    backgroundColor: '#fff',
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  }
}));

const Timesheet = () => {
  const classes = useStyles();

  const { customerRefs } = useFetchCustomerRefRecords();
  const { classRefs } = useFetchClassRefRecords();
  const { employeeRefs } = useFetchEmployeeRefRecords();

  const [filter, setFilter] = useState({
    timesheetPeriod: timesheetPeriodOptions[0],
    startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
    timesheetStatus: 'All',
    searchQuery: '',
    headerStatusChange: false
  });

  const { rows, setRows } = useTimesheetFilter(filter);
  const { timesheetStatusList, error, loading } = useFetchAllTimesheetStatus(filter, setRows);

  const [buttonList] = useState(['Send Reminder', 'Reject and send reminder', 'Send back to WithApprover']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [sendRemainderClicked, setSendRemainderClicked] = useState(false);
  const [hideComponent] = useState(true);
  const [submitToQBOLoading, setSubmitToQBOLoading] = useState(false);
  const [invalidRows, setInvalidRows] = useState([]);

  const [columns] = useState([
    {
      name: 'EmployeeRef',
      title: 'Employee Name',
      getCellValue: (row) => (row.EmployeeRef ? row.EmployeeRef.name : undefined)
    },
    { name: 'KeyPayEmployeeId', title: 'Employee ID' },
    { name: 'StartTime', title: 'Start Time', required: true },
    { name: 'FinishTime', title: 'End Time', required: true },
    {
      name: 'Hours',
      title: 'Hours',
      getCellValue: (row) => {
        if (row.StartTime && row.FinishTime) {
          const startDateMs = parse(row.StartTime, 'HH:mm', new Date(row.Date));
          const endDateMs = parse(row.FinishTime, 'HH:mm', new Date(row.Date));

          const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);

          return Number(diffHr);
        }
      },
      required: true
    },
    {
      name: 'Day',
      title: 'Day',
      required: true,
      getCellValue: (row) => {
        if (row.Date) {
          const columnDay = format(new Date(row.Date), 'EEEE');
          return columnDay;
        }
      }
    },
    { name: 'Date', title: 'Date', required: true },
    { name: 'Description', title: 'Comments', required: true },
    { name: 'status', title: 'Status', required: true },
    {
      name: 'CustomerRef',
      title: 'Customer Ref',
      getCellValue: (row) => (row.CustomerRef ? row.CustomerRef.name : undefined),
      required: true
    },
    {
      name: 'ClassRef',
      title: 'Service',
      getCellValue: (row) => (row.ClassRef ? row.ClassRef.name : undefined),
      required: true
    },
    { name: 'BillableStatus', title: 'Billable Status' },
    { name: 'variationNote', title: 'Variation Note' },
    { name: 'workType', title: 'Work Type External Id' },
    { name: 'timeSheetImageUrl', title: 'Image' },
    { name: 'VendorRef', title: 'Supplier', getCellValue: (row) => (row.VendorRef ? row.VendorRef.name : undefined) },
    { name: 'email', title: 'Email' },
    { name: 'jobTitle', title: 'Job Title' }
  ]);

  //admin timesheet need to show employee list, while adding or editing
  const [editingStateColumnExtensions] = useState([
    { columnName: 'KeyPayEmployeeId', editingEnabled: false },
    { columnName: 'KeyPayEmployeeId', editingEnabled: false },
    { columnName: 'status', editingEnabled: false },
    { columnName: 'Hours', editingEnabled: false },
    // { columnName: 'EmployeeRef', editingEnabled: false },
    { columnName: 'jobTitle', editingEnabled: false },
    { columnName: 'email', editingEnabled: false },
    { columnName: 'VendorRef', editingEnabled: false },
    { columnName: 'Day', editingEnabled: false },
    { columnName: 'workType', allowEditingWhenEditing: true, allowEditingWhenInserting: false }
  ]);

  const dateColumns = ['Date'];
  const [sorting, setSorting] = useState([{ columnName: 'EmployeeRef', direction: 'asc' }]);

  const [workType] = useState([
    { name: 'Annual Leave' },
    { name: "Personal/Carer's Leave" },
    { name: 'Leave without pay' },
    { name: 'Long Service Leave' },
    { name: 'School' },
    { name: 'Rostered Day Off' },
    { name: 'Public Holiday' }
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  // Initialize pageSize state with stored value or default value
  const [pageSize, setPageSize] = useState(() => {
    // Retrieve stored value from local storage
    // eslint-disable-next-line no-undef
    const storedPageSize = localStorage.getItem('pageSize');
    // Return stored value if available, otherwise return a default value
    return storedPageSize ? parseInt(storedPageSize, 10) : 25; // Default page size is 25
  });
  const [pageSizes] = useState([5, 10, 25, 50, 100]);

  let rowSelectionEnabled = (row) =>
    row.status === 'Approved' || row.status === 'WithEmployee' || row.status === 'WithApprover';

  if (filter.timesheetStatus === 'All') {
    rowSelectionEnabled = (row) => row.status !== 'Approved';
  }

  useEffect(() => {
    setSelection([]);
  }, [filter.timesheetStatus]);

  const changeSelection = (sel) => {
    setSelection(sel);
  };

  //change timesheet status to approved
  const handleApproveClick = (e) => {
    e.preventDefault();

    if (!selection.length) {
      return toast.error('Please select timesheet to approve');
    }
    setFilter({ ...filter, headerStatusChange: true });

    timesheetService
      .changeTimesheetStatusSubmittedByAdmin(selection)
      .then(() => {
        const updatedRows = rows.filter((r) => {
          return !selection.includes(r.id);
        });

        setRows(updatedRows);
        setSelection([]);

        toast.success('Successfully approved selected timesheet');
      })
      .catch((error) => {
        toast.error(error.message || 'Could not approve timesheet');
      })
      .finally(() => {
        setFilter({ ...filter, headerStatusChange: false });
      });
  };

  //submit timesheet to make bill in qbo and do something so that admin knows it's already submitted
  const handleTimesheetSubmitToQBO = () => {
    if (!validateDateDifference(filter.startDate, filter.endDate)) {
      return toast.error('Selected start date and end should be at least seven or multiple of seven days');
    }

    const selectedRows = rows.filter((row) => selection.includes(row.id));
    const invalidTimesheet = isSelectedTimeSheetsValid(selectedRows);

    if (invalidTimesheet.length) {
      const invalidRows = rows.filter((row, index) => invalidTimesheet.includes(index)).map((row) => row.id);
      setInvalidRows(invalidRows);
      setDialogOpen(false);
      return toast.error('Some rows are invalid. Please check invalid customerRef and classRef or workType.');
    }

    let filteredUserIds = rows.filter((r) => selection.includes(r.id)).map((r) => r.EmployeeRef.value);
    // eslint-disable-next-line no-undef
    filteredUserIds = [...new Set(filteredUserIds)];

    const weekStartDate = filter.startDate;
    const weekEndDate = format(new Date(filter.endDate), 'yyyy-MM-dd');

    const billPayload = { filteredUserIds, selection, weekStartDate, weekEndDate };

    setSubmitToQBOLoading(true);
    setDialogOpen(false);

    billService
      .createBill(billPayload)
      .then((data) => {
        if (data.data.failedUserIds.length) {
          // eslint-disable-next-line no-console, no-undef
          console.log('data.data.failedUserIds', data?.data?.failedUserIds);

          const usersDisplayNames = rows
            .filter((r) => data.data.failedUserIds.includes(r.EmployeeRef.value))
            .map((r) => r.EmployeeRef.name);

          // eslint-disable-next-line no-undef
          const uniqueSet = new Set(usersDisplayNames);
          const uniqueDisplayNames = Array.from(uniqueSet);

          const failedUserIdsWithErrors = data?.data?.failedUserIds.map((entry) => {
            const employeeId = entry.employeeId;
            const errorMessage = entry.error?.Fault?.Error[0]?.Detail;
            const displayName = rows.find((row) => row.EmployeeRef.value === employeeId)?.EmployeeRef.name || 'Unknown';
            return { displayName, errorMessage };
          });

          return toast.error(
            failedUserIdsWithErrors.map((user) => `${user.displayName}: ${user.errorMessage}`).join(', ') ||
              `Could not create bill for the following users: ${uniqueDisplayNames.join(', ')}.`
          );
        }

        if (data.data.succeededUserIds.length === filteredUserIds.length) {
          return toast.success('All users bill created.');
        }
      })
      .catch((error) => {
        if (error.response?.data?.length) {
          const userIdsData = error.response.data;
          // eslint-disable-next-line no-console, no-undef
          console.log('error is:', error?.response?.data);

          const usersDisplayNames = rows
            .filter((r) => userIdsData.includes(r.EmployeeRef.value))
            .map((r) => r.EmployeeRef.name);

          return toast.error(
            `Could not create bill of following user ${usersDisplayNames.join(',')} ${
              error?.response?.data?.data?.Fault?.Error[0]?.Detail
            }`
          );
        }

        const errorMessage = error.response.data.data?.Fault?.Error[0]?.Detail
          ? error.response?.data?.data?.Fault?.Error[0]?.Detail
          : error.response?.data?.message
          ? error.response?.data?.message
          : "Couldn't create bill";
        return toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitToQBOLoading(false);
        setSelection([]);
      });
  };

  const handleOptions = (e) => {
    if (!selection.length) return toast.error(`Please Select Timesheet to ${e.target.innerText}.`);

    if (e.target.innerText === 'Send Reminder' || e.target.textContent === 'Send Reminder') {
      setDialogOpen(true);
      setSendRemainderClicked(true);
      setDialogContent('Do you want to Send Reminder to Employee?');
      setAnchorEl(null);
    }

    if (e.target.innerText === 'Reject and send reminder' || e.target.textContent === 'Reject and send reminder') {
      handleRejectAndSendReminder();
    }

    if (e.target.innerText === 'Send back to WithApprover' || e.target.textContent === 'Send back to WithApprover') {
      handleSendBackToWithApprover();
    }
  };

  const handleRejectAndSendReminder = () => {
    const filteredUsers = removeDuplicateUser(rows, selection);
    const rejectedMessage = 'Your timesheets are rejected. Please edit and submit them again. Thank you';

    setAnchorEl(null);

    if (
      // eslint-disable-next-line no-undef
      confirm('Timesheet status wil be change to WithEmployee and rejected notification will be sent to the employee')
    ) {
      setFilter({ ...filter, headerStatusChange: true });

      timesheetService
        .rejectAndSendReminder(selection, filteredUsers, rejectedMessage)
        .then(() => {
          toast.success('successfully sent notification to the employee');
        })
        .catch((error) => {
          toast.error(error.message || "Couldn't send notification to the employee");
        })
        .finally(() => {
          setFilter({ ...filter, headerStatusChange: false });
        });
    }
  };

  const handleSendBackToWithApprover = () => {
    setAnchorEl(null);
    // eslint-disable-next-line no-undef
    if (confirm('Selected Approved timesheet will be back to WithApprover')) {
      setFilter({ ...filter, headerStatusChange: true });

      timesheetService
        .changeStatusBackToWithApprover(selection)
        .then((data) => {
          const filteredTimesheet = rows.filter((row) => row.id !== data.data._id);
          setRows(filteredTimesheet);
          toast.success('Successfully updated timesheet status');
        })
        .catch(() => {
          toast.error('Could not send back to WithApprover.');
        })
        .finally(() => {
          setFilter({ ...filter, headerStatusChange: false });
        });
    }
  };

  const handleSendRemainderToEmployee = () => {
    const filteredUsers = removeDuplicateUser(rows, selection);
    setDialogOpen(false);

    const reminderMessage =
      'Your Timesheets are pending to be sent to the Approver. Kindly login to the Verd Portal and send all the timesheet of the week.';

    setAnchorEl(null);

    timesheetService
      .sendEmail(filteredUsers, reminderMessage)
      .then(() => {
        toast.success('successfully sent remainder to the employee');
        setSelection([]);
      })
      .catch((error) => {
        toast.error(error.message || "Couldn't send remainder to the employee");
      });
  };

  if (error) {
    return <p>Error. Could not fetch timesheet data</p>;
  }
  if (loading) {
    return <p>Loading...</p>;
  }

  // Function to handle change in page size
  const handlePageSizeChange = (newPageSize) => {
    // Update local storage
    // eslint-disable-next-line no-undef
    localStorage.setItem('pageSize', newPageSize);
    // Update state with new page size

    setPageSize(newPageSize);
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 10000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <TimesheetStatus
        timesheetStatusList={timesheetStatusList}
        filter={filter}
        setFilter={setFilter}
        setSelection={setSelection}
      />

      <div className={classes.filterButtonWrapper}>
        <div className={classes.filterWrapper}>
          <TimesheetFilter filter={filter} setFilter={setFilter} />
          <Button
            onClick={() =>
              setFilter({
                ...filter,
                timesheetPeriod: timesheetPeriodOptions[0],
                startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
                endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
                timesheetStatus: 'All',
                searchQuery: ''
              })
            }
            className={classes.resetButton}
          >
            <RefreshIcon />
            Reset
          </Button>
        </div>
        <div className={classes.buttonWrapper} style={{ backgroundColor: submitToQBOLoading ? 'white' : '' }}>
          {filter.timesheetStatus !== 'Approved' ? (
            <Button variant="contained" color="primary" onClick={handleApproveClick} className={classes.approveButton}>
              Approve
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!selection.length) return toast.error('Please select timesheet rows to submit');
                setDialogContent(' Are you sure you want to create bill with selected Timesheet?');
                setDialogOpen(true);
              }}
              className={classes.approveButton}
              disabled={submitToQBOLoading}
            >
              {submitToQBOLoading && (
                <>
                  <CircularProgress size={20} style={{ color: 'white' }} />
                  &nbsp;&nbsp;&nbsp;
                </>
              )}
              Submit
            </Button>
          )}
          <ButtonMenu
            buttonList={buttonList}
            handleOptions={handleOptions}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            filter={filter}
          />
        </div>
      </div>

      <AlertDialog
        handleAgreeClick={sendRemainderClicked ? handleSendRemainderToEmployee : handleTimesheetSubmitToQBO}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        sendRemainderClicked={sendRemainderClicked}
        dialogContent={dialogContent}
      />

      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          value={filter.searchQuery}
          onChange={(e) => setFilter({ ...filter, searchQuery: e.target.value })}
          placeholder="Search..."
          style={{ border: 'none', outline: 'none' }}
        />
      </div>

      {customerRefs.length > 0 && classRefs.length > 0 && (
        <AdminTimesheetTable
          rows={rows}
          setRows={setRows}
          customerRefs={customerRefs}
          classRefs={classRefs}
          employeeRefs={employeeRefs}
          selection={selection}
          rowSelectionEnabled={rowSelectionEnabled}
          changeSelection={changeSelection}
          disableEditAndDelete={(row) => {
            return row.status === 'Approved';
          }}
          columns={columns}
          hideComponent={hideComponent}
          dateColumns={dateColumns}
          sorting={sorting}
          setSorting={setSorting}
          filter={filter}
          editingStateColumnExtensions={editingStateColumnExtensions}
          workType={workType}
          invalidRows={invalidRows}
          setInvalidRows={setInvalidRows}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          pageSizes={pageSizes}
        />
      )}
    </div>
  );
};

export default Timesheet;

function removeDuplicateUser(rows, selection) {
  let selectedUser = rows.filter((r) => selection.includes(r.id)).map((r) => r.EmployeeRef);
  const selectedUserIds = selectedUser.map((s) => s.value);
  const filteredUsers = selectedUser.filter((user, index) => !selectedUserIds.includes(user.value, index + 1));

  return filteredUsers;
}
