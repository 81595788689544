import axios from 'axios';
import { BASE_URL } from '../components/Constant';

function getFlespiDeviceSetting(deviceId) {
  const endpoint = BASE_URL + 'flespiDevice/getFlespiDeviceSetting';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      deviceId: deviceId
    }
  });
}

function updateFlespiDeviceSetting(devicePayload) {
  const endpoint = BASE_URL + 'flespiDevice/updateFlespiDeviceSetting';

  return axios.put(
    endpoint,
    { devicePayload: devicePayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const flespiDeviceService = {
  getFlespiDeviceSetting,
  updateFlespiDeviceSetting
};

export default flespiDeviceService;
