import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));

const priorityOptions = [
  { value: 'disable', label: 'Disable', id: 0 },
  { value: 'low', label: 'Low', id: 1 },
  { value: 'high', label: 'High', id: 2 },
  { value: 'panic', label: 'Panic', id: 3 }
];

const modeOptions = [
  { value: 'continuous', label: 'Continuous', id: 0 },
  { value: 'betweenRecords', label: 'Between Records', id: 1 }
];

const TripScenarioParameters = ({ tripScenarioRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      eco_score: tripScenarioRecord[0]?.current?.eco_score,
      eco_score_checked: tripScenarioRecord[0]?.current?.eco_score ? true : false,
      ign_off_timeout: tripScenarioRecord[0]?.current?.ign_off_timeout,
      mode: tripScenarioRecord[0]?.current?.mode,
      prio: tripScenarioRecord[0]?.current?.prio,
      record: tripScenarioRecord[0]?.current?.record,
      remember_ibutton: tripScenarioRecord[0]?.current?.remember_ibutton,
      start_speed: tripScenarioRecord[0]?.current?.start_speed
    }
  });

  const handleTripScenario = (data) => {
    setLoading(true);

    const tipScenarioPayload = {
      deviceId: tripScenarioRecord[0]?.device_id,
      settSelector: 'trip_scenario',
      properties: {
        eco_score: data.eco_score,
        ign_off_timeout: data.ign_off_timeout,
        mode: data.mode,
        prio: data.prio,
        record: data.record,
        remember_ibutton: data.remember_ibutton,
        start_speed: data.start_speed
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('tipScenarioPayload', tipScenarioPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(tipScenarioPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated trip scenario parameters.');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update trip scenario parameters.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const eco_score = watch('eco_score');

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleTripScenario)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Trip Scenario Parameters</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Trip Scenario Parameters</Typography>

            <div className={classes.accordionDetailsItem}>
              {/* priority */}
              <Controller
                control={control}
                name="prio"
                render={({ field }) => (
                  <>
                    <label htmlFor="prio-select" style={{ marginBottom: '-14px', color: 'gray', fontSize: '12px' }}>
                      Priority
                    </label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      isSearchable={true}
                      name="prio"
                      options={priorityOptions}
                      value={priorityOptions.find((option) => option.id === field.value)}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                      // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                    />
                  </>
                )}
              />

              {/*  record  */}
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="record"
                  render={({ field }) => (
                    <Switch
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      name="record"
                      color="primary"
                    />
                  )}
                />
                <label style={{ marginTop: '10px' }}>Eventual Records</label>
              </div>

              {/*  mode */}
              <Controller
                control={control}
                name="mode"
                render={({ field }) => (
                  <>
                    <label htmlFor="mode-select" style={{ marginBottom: '-14px', color: 'gray', fontSize: '12px' }}>
                      Mode
                    </label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      isSearchable={true}
                      name="mode"
                      options={modeOptions}
                      value={modeOptions.find((option) => option.id === field.value)}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                      // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                    />
                  </>
                )}
              />

              {/* Start speed */}
              <Controller
                control={control}
                name="start_speed"
                render={({ field }) => (
                  <TextField
                    label="Start speed"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="start_speed"
                    name="start_speed"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />

              {/* Ignition off timeout */}
              <Controller
                control={control}
                name="ign_off_timeout"
                render={({ field }) => (
                  <TextField
                    label="Ignition off timeout"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="ign_off_timeout"
                    name="ign_off_timeout"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />

              {/* eco score */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {!eco_score && <label>Eco score allowed events</label>}

                {eco_score && (
                  <Controller
                    control={control}
                    name="eco_score"
                    render={({ field }) => (
                      <TextField
                        required
                        variant="outlined"
                        size="small"
                        {...field}
                        autoFocus
                        id="eco_score"
                        name="eco_score"
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        label="Eco score allowed events"
                      />
                    )}
                  />
                )}

                <Controller
                  control={control}
                  name="eco_score_checked"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (!e.target.checked) {
                          setValue('eco_score', null);
                        }
                        if (e.target.checked) {
                          setValue('eco_score', 1);
                        }
                      }}
                      color="primary"
                      name="eco_score_checked"
                    />
                  )}
                />
              </div>

              {/* remember ibutton */}
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="remember_ibutton"
                  render={({ field }) => (
                    <Switch
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      name="remember_ibutton"
                      color="primary"
                    />
                  )}
                />
                <label style={{ marginTop: '10px' }}>Remember iButton</label>
              </div>
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={Object.keys(errors).length > 0 || loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default TripScenarioParameters;

TripScenarioParameters.propTypes = {
  tripScenarioRecord: PropTypes.array
};
