import React, { useEffect, useState } from 'react';
import bluetoothToolService from '../../services/BluetoothToolService';
import { Button, Typography } from '@material-ui/core';
import BeaconTable from './table/BeaconTable';
import { getFormattedBeacons } from './table/helper';

const Beacon = () => {
  const [pairedBeaconRecords, setPairedBeaconRecords] = useState([]);
  const [beaconScan, setBeaconScan] = useState(false);

  useEffect(() => {
    const fetchRouterPairedDevices = async () => {
      try {
        const pairedDevicesResponse = await bluetoothToolService.getRouterPairedDevices();

        const pairedDevices = pairedDevicesResponse?.data?.data;
        const formattedBeacons = getFormattedBeacons(pairedDevices);

        setPairedBeaconRecords(formattedBeacons);
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error while fetching paired router is:', error);
      }
    };
    fetchRouterPairedDevices();
  }, []);

  const handleBeaconScan = () => {
    // eslint-disable-next-line no-console, no-undef
    console.log('k cha ta asko state pairedBeaconRecords', beaconScan);
    setBeaconScan(!beaconScan);
    //pachadi fetch garnu paryo
    //aayesi setBeaconScan false
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <Button onClick={handleBeaconScan} disabled={beaconScan} variant="contained" color="primary">
          Scan
        </Button>
      </div>

      {/* TODO: once scanned show the unpaired devices list */}
      <div>
        <Typography>Paired devices</Typography>
      </div>
      {pairedBeaconRecords.length > 0 && <BeaconTable rows={pairedBeaconRecords} />}
    </div>
  );
};

export default Beacon;
