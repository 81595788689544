import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const beaconRecordOptions = [
  { value: 'disabled', label: 'Disabled', id: 0 },
  { value: 'all', label: 'ALL', id: 1 },
  { value: 'configured', label: 'Configured', id: 2 }
];

const beaconDetectionOptions = [
  { value: 'event', label: 'Event', id: 0 },
  { value: 'periodic', label: 'Periodic', id: 1 }
];

const BeaconSettings = ({ currentRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      period_on_move: currentRecord[0]?.current?.period_on_move,
      period_on_stop: currentRecord[0]?.current?.period_on_stop,
      detection: currentRecord[0]?.current?.detection,
      record: currentRecord[0]?.current?.record
    }
  });

  const handleBeaconSettings = (data) => {
    setLoading(true);

    let beaconSettingsPayload = {
      deviceId: currentRecord[0]?.device_id,
      settSelector: 'beacon',
      properties: {
        period_on_move: data.period_on_move,
        period_on_stop: data.period_on_stop,
        detection: data.detection,
        record: data.record
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(beaconSettingsPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success(`Successfully updated beacon settings`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || `Unable to update beacon settings`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleBeaconSettings)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Beacon settings</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Beacon settings</Typography>

            {/* Beacon detection  */}
            <div style={{ marginBottom: '0.5rem' }}>
              <Typography style={{ fontSize: '13px', color: '#757575' }}>Beacon detection</Typography>
              <Controller
                control={control}
                name="detection"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={beaconDetectionOptions.find((option) => option.id === field.value)}
                    isSearchable={true}
                    name="detection"
                    options={beaconDetectionOptions}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
            </div>

            {/* record */}
            <div>
              <Typography style={{ fontSize: '13px', color: '#757575' }}>Beacon Records</Typography>
              <Controller
                control={control}
                name="record"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={beaconRecordOptions.find((option) => option.id === field.value)}
                    isSearchable={true}
                    name="record"
                    options={beaconRecordOptions}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
            </div>

            {/* period_on_move */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="period_on_move"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Record period on move"
                    name="period_on_move"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.period_on_move}
                    helperText={errors.period_on_move?.message}
                  />
                )}
              />
            </div>

            {/* period_on_stop */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="period_on_stop"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Record period on stop"
                    name="period_on_stop"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.period_on_stop}
                    helperText={errors.period_on_stop?.message}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default BeaconSettings;

BeaconSettings.propTypes = {
  currentRecord: PropTypes.array,
  settSelector: PropTypes.string,
  heading: PropTypes.string
};
