import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  }
}));

const HomeGSMNetworkSetting = ({ homeGSMSettingRecord }) => {
  // eslint-disable-next-line no-console, no-undef
  console.log('homeGSMSettingRecord', homeGSMSettingRecord);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      onMoveMinAngle: homeGSMSettingRecord[0]?.current?.on_move?.min_angle || '',
      onMoveMinDistance: homeGSMSettingRecord[0]?.current?.on_move?.min_distance || '',
      onMoveMinPeriod: homeGSMSettingRecord[0]?.current?.on_move?.min_period || '',
      onMoveMinRecords: homeGSMSettingRecord[0]?.current?.on_move?.min_records || '',
      onMoveMinSpeedDelta: homeGSMSettingRecord[0]?.current?.on_move?.min_speed_delta || '',
      onMoveSendPeriod: homeGSMSettingRecord[0]?.current?.on_move?.send_period || '',

      onStopMinPeriod: homeGSMSettingRecord[0]?.current?.on_stop?.min_period || '',
      onStopMinRecords: homeGSMSettingRecord[0]?.current?.on_stop?.min_records || '',
      onStopSendPeriod: homeGSMSettingRecord[0]?.current?.on_stop?.send_period || ''
    }
  });

  const handleHomeGSMSetting = (data) => {
    setLoading(true);

    const homeGSMPayload = {
      deviceId: homeGSMSettingRecord[0]?.device_id,
      settSelector: 'home_network',
      properties: {
        on_move: {
          min_angle: data.onMoveMinAngle,
          min_distance: data.onMoveMinDistance,
          min_period: data.onMoveMinPeriod,
          min_records: data.onMoveMinRecords,
          min_speed_delta: data.onMoveMinSpeedDelta,
          send_period: data.onMoveSendPeriod
        },
        on_stop: {
          min_period: data.onStopMinPeriod,
          min_records: data.onStopMinRecords,
          send_period: data.onStopSendPeriod
        }
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('homeGSMPayload', homeGSMPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(homeGSMPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated home gsm network setting.');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update home gsm network setting.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleHomeGSMSetting)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Home GSM Network Settings</Typography>
                <Typography className={classes.secondaryHeading}>
                  Data acquisition parameters for home GSM network.
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ', marginBottom: '10px' }}>Home GSM Network Settings</Typography>
            {/* on stop */}
            <div className={classes.accordionDetailsItem}>
              <Typography style={{ color: '#9e9e9e ', marginBottom: '10px' }}>Vehicle On Stop</Typography>
              <Controller
                control={control}
                name="onStopMinPeriod"
                render={({ field }) => (
                  <TextField
                    label="Data Recording Timeout"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onStopMinPeriod"
                    name="onStopMinPeriod"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> seconds</InputAdornment>
                    }}
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                label="Saved Records"
                control={control}
                name="onStopMinRecords"
                render={({ field }) => (
                  <TextField
                    label="Saved Records"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onStopMinRecords"
                    name="onStopMinRecords"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                label="Send Period"
                control={control}
                name="onStopSendPeriod"
                render={({ field }) => (
                  <TextField
                    label="Send Period"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onStopSendPeriod"
                    name="onStopSendPeriod"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> seconds</InputAdornment>
                    }}
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
            </div>

            {/* moving */}
            <div className={classes.accordionDetailsItem}>
              <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Vehicle Moving</Typography>
              <Controller
                control={control}
                name="onMoveMinAngle"
                render={({ field }) => (
                  <TextField
                    label="Timeout"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveMinAngle"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                    }}
                    name="onMoveMinDistance"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                control={control}
                name="onMoveMinDistance"
                render={({ field }) => (
                  <TextField
                    label="Distance"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveMinDistance"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">meters</InputAdornment>
                    }}
                    name="onMoveMinDistance"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                control={control}
                name="onMoveMinPeriod"
                render={({ field }) => (
                  <TextField
                    label="Angle Change"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveMinPeriod"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">degrees</InputAdornment>
                    }}
                    name="onMoveMinPeriod"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                control={control}
                name="onMoveMinRecords"
                render={({ field }) => (
                  <TextField
                    label="Speed Change"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveMinRecords"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> km/hr</InputAdornment>
                    }}
                    name="onMoveMinRecords"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                control={control}
                name="onMoveMinSpeedDelta"
                render={({ field }) => (
                  <TextField
                    label="Saved Records"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveMinSpeedDelta"
                    name="onMoveMinSpeedDelta"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
              <Controller
                control={control}
                name="onMoveSendPeriod"
                render={({ field }) => (
                  <TextField
                    label="Send Periods"
                    required
                    variant="outlined"
                    size="small"
                    {...field}
                    className={classes.textField}
                    autoFocus
                    id="onMoveSendPeriod"
                    name="onMoveSendPeriod"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> seconds</InputAdornment>
                    }}
                    type="number"
                    style={{ marginBottom: '1rem' }}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={Object.keys(errors).length > 0 || loading} // Corrected logic
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default HomeGSMNetworkSetting;

HomeGSMNetworkSetting.propTypes = {
  homeGSMSettingRecord: PropTypes.array
};
