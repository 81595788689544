import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import FaultCodes from './FaultCodes';
import VIN from './VIN';
import ObdTabSettSelector from './ObdTabSettSelector';

const useStyles = makeStyles((theme) => ({
  obdTab: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  obdTabDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}));

const ObdTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.obdTab}>
      <div className={classes.obdTabDiv}>
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_number_of_dtc_io')}
          settSelector="obd_number_of_dtc_io"
          heading="Number Of DTC"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_short_fuel_trim_io')}
          settSelector="obd_short_fuel_trim_io"
          heading="Short Fuel Trim"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_engine_rpm_io')}
          settSelector="obd_engine_rpm_io"
          heading="Engine RPM"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_intake_air_temperature_io')}
          settSelector="obd_intake_air_temperature_io"
          heading="Intake air temperature"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_run_time_since_engine_start_io')}
          settSelector="obd_run_time_since_engine_start_io"
          heading="Run time since engine start"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_direct_fuel_rail_pressure_io')}
          settSelector="obd_direct_fuel_rail_pressure_io"
          heading="Direct fuel rail pressure"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fuel_level_io')}
          settSelector="obd_fuel_level_io"
          heading="Fuel level"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_control_module_voltage_io')}
          settSelector="obd_control_module_voltage_io"
          heading="Control module voltage"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_time_run_with_mil_on_io')}
          settSelector="obd_time_run_with_mil_on_io"
          heading="Time run with MIL on"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_absolute_fuel_rail_pressure_io')}
          settSelector="obd_absolute_fuel_rail_pressure_io"
          heading="Absolute fuel rail pressure"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fuel_injection_timing_io')}
          settSelector="obd_fuel_injection_timing_io"
          heading="Fuel injection timing"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_intake_map_2b_io')}
          settSelector="obd_intake_map_2b_io"
          heading="Intake MAP 2b"
        />

        <FaultCodes currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fault_codes_io')} />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_oem_fuel_level_io')}
          settSelector="obd_oem_fuel_level_io"
          heading="OEM Fuel Level"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_oem_battery_charge_level_io')}
          settSelector="obd_oem_battery_charge_level_io"
          heading="OEM Battery Charge Level"
        />
      </div>

      <div className={classes.obdTabDiv}>
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_engine_load_io')}
          settSelector="obd_engine_load_io"
          heading="Engine Load"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fuel_pressure_io')}
          settSelector="obd_fuel_pressure_io"
          heading="Fuel Pressure"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_vehicle_speed_io')}
          settSelector="obd_vehicle_speed_io"
          heading="Vehicle speed"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_maf_io')}
          settSelector="obd_maf_io"
          heading="MAF"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_distance_traveled_mil_on_io')}
          settSelector="obd_distance_traveled_mil_on_io"
          heading="Distance traveled MIL on"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_commanded_egr_io')}
          settSelector="obd_commanded_egr_io"
          heading="Commanded EGR"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter(
            (record) => record.name === 'obd_distance_traveled_since_codes_clear_io'
          )}
          settSelector="obd_distance_traveled_since_codes_clear_io"
          heading="Distance traveled since codes clear"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_absolute_load_value_io')}
          settSelector="obd_absolute_load_value_io"
          heading="Absolute load value"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter(
            (record) => record.name === 'obd_time_since_trouble_codes_cleared_io'
          )}
          settSelector="obd_time_since_trouble_codes_cleared_io"
          heading="Time since trouble codes cleared"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter(
            (record) => record.name === 'obd_hybrid_battery_pack_remaining_life_io'
          )}
          settSelector="obd_hybrid_battery_pack_remaining_life_io"
          heading="Hybrid battery pack remaining life"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fuel_rate_io')}
          settSelector="obd_fuel_rate_io"
          heading="Fuel Rate"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_hybrid_vehicle_system_voltage_io')}
          settSelector="obd_hybrid_vehicle_system_voltage_io"
          heading="Hybrid Vehicle System Voltage"
        />

        <VIN currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_vin_io')} />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_oem_remaining_distance_io')}
          settSelector="obd_oem_remaining_distance_io"
          heading="OEM Remaining Distance"
        />
      </div>

      <div className={classes.obdTabDiv}>
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_coolant_temperature_io')}
          settSelector="obd_coolant_temperature_io"
          heading="Coolant Temperature"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_intake_map_io')}
          settSelector="obd_intake_map_io"
          heading="Intake Map"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_timing_advance_io')}
          settSelector="obd_timing_advance_io"
          heading="Timing advance"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_throttle_position_io')}
          settSelector="obd_throttle_position_io"
          heading="Throttle position"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_relative_fuel_rail_pressure_io')}
          settSelector="obd_relative_fuel_rail_pressure_io"
          heading="Relative fuel rail pressure"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_egr_error_io')}
          settSelector="obd_egr_error_io"
          heading="EGR error"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_barometric_pressure_io')}
          settSelector="obd_barometric_pressure_io"
          heading="Barometric pressure"
        />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_ambient_air_temperature_io')}
          settSelector="obd_ambient_air_temperature_io"
          heading="Ambient air temperature"
        />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_fuel_type_io')}
          settSelector="obd_fuel_type_io"
          heading="Fuel Type"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_engine_oil_temperature_io')}
          settSelector="obd_engine_oil_temperature_io"
          heading="Engine oil temperature"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_command_equivalence_ratio_io')}
          settSelector="obd_command_equivalence_ratio_io"
          heading="Command Equivalence Ratio"
        />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_hybrid_vehicle_system_current_io')}
          settSelector="obd_hybrid_vehicle_system_current_io"
          heading="Hybrid Vehicle System Current"
        />

        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_oem_total_mileage_io')}
          settSelector="obd_oem_total_mileage_io"
          heading="OEM Total Mileage"
        />
        <ObdTabSettSelector
          currentRecord={selectedTabSettings.filter((record) => record.name === 'obd_oem_battery_charge_state_io')}
          settSelector="obd_oem_battery_charge_state_io"
          heading="OEM Battery Charge State"
        />
      </div>
    </div>
  );
};

export default ObdTab;

ObdTab.propTypes = {
  selectedTabSettings: PropTypes.any
};
