import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  TextField,
  AccordionActions,
  Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column'
  }
}));

const protocolOptions = [
  { value: 'tcp', label: 'TCP', id: 0 },
  { value: 'udp', label: 'UDP', id: 1 }
];

const modeOptions = [
  { value: 'disabled', label: 'Disabled', id: 0 },
  { value: 'backup', label: 'Backup', id: 1 },
  { value: 'duplicate', label: 'Duplicate', id: 2 }
];

const SecondaryGPRSServerSetup = ({ secondaryGPRSServerSetupRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      host: secondaryGPRSServerSetupRecord[0]?.current?.host,
      port: secondaryGPRSServerSetupRecord[0]?.current?.port,
      protocol: secondaryGPRSServerSetupRecord[0]?.current?.protocol,
      mode: secondaryGPRSServerSetupRecord[0]?.current?.mode
    }
  });

  const handleSecondaryGPRSServerSetup = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('data', data);
    setLoading(true);

    const secondaryGPRSServerSetupPayload = {
      deviceId: secondaryGPRSServerSetupRecord[0]?.device_id,
      settSelector: 'backup_server',
      properties: {
        host: data.host,
        port: data.port,
        protocol: data.protocol,
        mode: data.mode
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('secondaryGPRSServerSetupPayload', secondaryGPRSServerSetupPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(secondaryGPRSServerSetupPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated backup gprs server setting');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update backup gprs server settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors are,', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleSecondaryGPRSServerSetup)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Secondary GPRS Server Setup</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Secondary GPRS Server Setup</Typography>

            <div className={classes.accordionDetailsItem}>
              {/* <label
                htmlFor="mode-select"
                style={{ display: 'block', color: 'gray' }}
                className="dis:block text-sm font-medium text-gray-700"
              >
                Select Mode
              </label> */}
              <Controller
                control={control}
                name="mode"
                render={({ field }) => (
                  <>
                    <label htmlFor="mode-select" style={{ marginBottom: '-14px', color: 'gray', fontSize: '12px' }}>
                      Mode
                    </label>
                    <ReactSelect
                      id="mode-select"
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="mode"
                      options={modeOptions}
                      value={modeOptions.find((option) => option?.id === field?.value)}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                      // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </>
                )}
              />

              <Controller
                control={control}
                name="host"
                render={({ field }) => (
                  <TextField
                    label="Host"
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="host"
                    name="host"
                    type="text"
                  />
                )}
              />

              <Controller
                control={control}
                name="port"
                render={({ field }) => (
                  <TextField
                    label="Port"
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="port"
                    name="port"
                    type="text"
                  />
                )}
              />

              <Controller
                control={control}
                name="protocol"
                render={({ field }) => (
                  <>
                    <label htmlFor="protocol-select" style={{ marginBottom: '-14px', color: 'gray', fontSize: '12px' }}>
                      Protocol
                    </label>
                    <ReactSelect
                      id="protocol-select"
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="protocol"
                      options={protocolOptions}
                      value={protocolOptions.find((option) => option?.id === field?.value)}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                      // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </>
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default SecondaryGPRSServerSetup;

SecondaryGPRSServerSetup.propTypes = {
  secondaryGPRSServerSetupRecord: PropTypes.array
};
