import React, { useEffect, useState } from 'react';
import vehicleTrackerService from '../../services/VehicleTrackerService';

import { getFormattedTrackerRecords } from '../VehicleTracker/helper/helper';
import toast from 'react-hot-toast';
import TrackerList from './TrackerList';
import TrackerTabs from './TrackerTabs/TrackerTabs';
import flespiDeviceService from '../../services/FlespiDeviceService';
import { findTabSettingRecords } from './TrackerTabs/helper/helper';
import TrackerCard from './Card/TrackerCard';

const Tracker = () => {
  const [trackerRecord, setTrackerRecord] = useState();
  const [loading, setLoading] = useState(false);
  const [trackerItemClicked, setTrackerItemClicked] = useState(false);
  const [deviceId, setDeviceId] = useState(null);

  // const [settingRecords, setSettingRecords] = useState([]);
  const [selectedTabSettings, setSelectedTabSettings] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchTrackerRecords = async () => {
      try {
        setLoading(true);
        const trackerResponse = await vehicleTrackerService.getTrackerRecords();
        const trackerRecords = trackerResponse?.data?.data;

        const formattedRecord = getFormattedTrackerRecords(trackerRecords);
        setTrackerRecord(formattedRecord);
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Error while fetching tracker records');
      } finally {
        setLoading(false);
      }
    };
    fetchTrackerRecords();
  }, []);

  useEffect(() => {
    const fetchDeviceSetting = async () => {
      try {
        setLoading(true);
        if (deviceId) {
          const deviceSettingResponse = await flespiDeviceService.getFlespiDeviceSetting(deviceId);
          const deviceSettings = deviceSettingResponse?.data?.data;
          // setSettingRecords(deviceSettings);

          if (deviceSettings.length > 0) {
            const filteredSettings = findTabSettingRecords(deviceSettings, tabValue);
            setSelectedTabSettings(filteredSettings);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };
    fetchDeviceSetting();
  }, [deviceId, tabValue]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBackButton = () => {
    setTrackerItemClicked(false);
    setDeviceId(null);
    setTabValue(0);
  };

  return (
    <div>
      {loading ? (
        'Loading...'
      ) : trackerItemClicked ? (
        <div>
          <TrackerCard
            trackerItem={trackerRecord.filter((record) => record.deviceId === deviceId)}
            handleBackButton={handleBackButton}
          />
          <TrackerTabs
            value={tabValue}
            selectedTabSettings={selectedTabSettings}
            handleChange={handleChange}
            deviceId={deviceId}
          />
        </div>
      ) : !trackerItemClicked && trackerRecord?.length > 0 ? (
        <TrackerList
          trackerRecord={trackerRecord.filter((record) => record.deviceId)}
          setTrackerItemClicked={setTrackerItemClicked}
          setDeviceId={setDeviceId}
        />
      ) : (
        <p>No tracker records available.</p>
      )}
    </div>
  );
};

export default Tracker;
