import axios from 'axios';
import { BASE_URL } from '../components/Constant';

function getBeaconRouteHistory(beaconPayload) {
  const endpoint = BASE_URL + 'bluetoothTool/getBeaconRouteHistory';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      beaconPayload: beaconPayload
    }
  });
}

function getRouterPairedDevices() {
  const endpoint = BASE_URL + 'bluetoothTool/getRouterPairedDevices';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const bluetoothToolService = {
  getBeaconRouteHistory,
  getRouterPairedDevices
};

export default bluetoothToolService;
