import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SystemParamsTab from './SystemParamsTab/SystemParamsTab';
import ConnectivityTab from './ConnectivityTab/ConnectivityTab';
import TrackingTab from './TrackingTab/TrackingTab';
import ConfigurationTab from './ConfigurationTab/ConfigurationTab';
import MiscellaneousTab from './MiscellaneousTab/MiscellaneousTab';
import AccelerometerTab from './AccelerometerTab/AccelerometerTab';
import ObdTab from './ObdTab/ObdTab';
import CanTab from './CanTab/CanTab';
import BluetoothTab from './BluetoothTab/BluetoothTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    backgroundColor: '#9e9e9e'
  }
}));

const TrackerTabs = ({ value, selectedTabSettings, handleChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          indicatorColor="primary"
          variant="scrollable"
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
        >
          <Tab label="SYSTEM PARAMS" {...a11yProps(0)} />
          <Tab label="CONNECTIVITY" {...a11yProps(1)} />
          <Tab label="CONFIGURATION" {...a11yProps(2)} />
          <Tab label="TRACKING" {...a11yProps(3)} />
          <Tab label="MISCELLANEOUS" {...a11yProps(4)} />
          <Tab label="ACCELEROMETER" {...a11yProps(5)} />
          <Tab label="OBD" {...a11yProps(6)} />
          <Tab label="CAN" {...a11yProps(7)} />
          <Tab label="BLUETOOTH" {...a11yProps(8)} />
          <Tab label="ACTIONS" {...a11yProps(9)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <SystemParamsTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ConnectivityTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ConfigurationTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TrackingTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MiscellaneousTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AccelerometerTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ObdTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <CanTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <BluetoothTab selectedTabSettings={selectedTabSettings} />
      </TabPanel>
      <TabPanel value={value} index={9}>
        ACTIONS
      </TabPanel>
    </div>
  );
};

export default TrackerTabs;

TrackerTabs.propTypes = {
  setTrackerItemClicked: PropTypes.func,
  setDeviceId: PropTypes.any,
  value: PropTypes.any,
  setValue: PropTypes.func,
  selectedTabSettings: PropTypes.array,
  handleChange: PropTypes.func,
  handleBackButton: PropTypes.func
};
