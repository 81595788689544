import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Divider, List, ListItemText, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  listItemText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0rem 2rem'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '2rem',
    marginBottom: '1rem',
    cursor: 'pointer'
  }
}));

const BeaconDetailDialog = ({ value }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Details
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{'Device Data'}</DialogTitle>
        <div onClick={handleClose} className={classes.closeIcon}>
          <CloseIcon />
        </div>
        <DialogContent>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemText primary="MAC ADDRESS" secondary={value.mac} className={classes.listItemText} />
            <Divider />
            <ListItemText primary="PROTOCOL" secondary={value.protocol} className={classes.listItemText} />
            <Divider />

            <ListItemText primary="POWER" secondary={value.power} className={classes.listItemText} />
            <Divider />

            <ListItemText primary="RSSI" secondary={value?.rssi || null} className={classes.listItemText} />
            <Divider />

            <ListItemText primary="NAMESPACE ID" secondary={value.NamespaceID} className={classes.listItemText} />
            <Divider />

            <ListItemText
              primary="UPDATED"
              secondary={value?.updatedAt && format(new Date(value?.updatedAt), 'dd-MM-yyyy')}
              className={classes.listItemText}
            />
            <Divider />

            <ListItemText primary="NAME" secondary={value.name || ''} className={classes.listItemText} />
            <Divider />

            <ListItemText primary="INSTANCE ID" secondary={value.InstanceID} className={classes.listItemText} />
            <Divider />

            <ListItemText primary="UUID" secondary={value.UUID} className={classes.listItemText} />
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BeaconDetailDialog;

BeaconDetailDialog.propTypes = {
  value: PropTypes.shape({
    mac: PropTypes.any,
    protocol: PropTypes.any,
    power: PropTypes.any,
    NamespaceID: PropTypes.any,
    updatedAt: PropTypes.any,
    name: PropTypes.any,
    InstanceID: PropTypes.any,
    UUID: PropTypes.any,
    rssi: PropTypes.rssi
  })
};
