import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';
import { getIgnitionDetectionObject } from '../helper/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  textField: {
    width: '25ch'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ignitionDetectionSourceOptions = [
  { value: 'digitalInput', label: 'Digital Input', id: 1 },
  { value: 'accelerometer', label: 'Accelerometer', id: 2 },
  { value: 'digitalInputAndAccelerometer', label: 'Digital Input and Accelerometer', id: 3 },
  { value: 'powerVoltage', label: 'Power Voltage', id: 4 },
  { value: 'digitalInputAndPowerVoltage', label: 'Digital Input and Power Voltage', id: 5 },
  { value: 'accelerometerAndPowerVoltage', label: 'Accelerometer and Power Voltage', id: 6 },
  { value: 'digitalInputAccelerometerAndPowerVoltage', label: 'Digital Input ,Accelerometer and Power Voltage', id: 7 },
  { value: 'engineRPM', label: 'Engine RPM', id: 8 },
  { value: 'digitalInputAndEngineRPM', label: 'Digital Input and Engine RPM', id: 9 },
  { value: 'accelerometerAndEngineRPM', label: 'Accelerometer and Engine RPM', id: 10 },
  { value: 'digitalInputAccelerometerAndEngineRPM', label: 'Digital Input ,Accelerometer and Engine RPM', id: 11 },
  { value: 'powerVoltageAndEngineRPM', label: 'Power Voltage and Engine RPM', id: 12 },
  { value: 'digitalInputPowerVoltageAndEngineRPM', label: 'Digital Input ,Power Voltage and Engine RPM', id: 13 },
  { value: 'accelerometerPowerVoltageAndEngineRPM', label: 'Accelerometer ,Power Voltage and Engine RPM', id: 14 },
  {
    value: 'digitalInputAccelerometerPowerVoltageAndEngineRPM',
    label: 'Digital Input ,Accelerometer ,Power Voltage and Engine RPM',
    id: 15
  }
];

const IgnitionDetectionSource = ({ ignitionDetectionSourceRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log('ignitionDetectionSourceRecord', ignitionDetectionSourceRecord);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      high: ignitionDetectionSourceRecord[0]?.current?.ign?.high,
      low: ignitionDetectionSourceRecord[0]?.current?.ign?.low,
      start: ignitionDetectionSourceRecord[0]?.current?.ign?.start,
      stop: ignitionDetectionSourceRecord[0]?.current?.ign?.stop,
      source: ignitionDetectionSourceRecord[0]?.current?.ign?.source
    }
  });

  const watchSource = watch('source', false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIgnitionDetectionSetting = (data) => {
    setLoading(true);

    const ignObject = getIgnitionDetectionObject(data);

    let ignitionDetectionSourcePayload = {
      deviceId: ignitionDetectionSourceRecord[0]?.device_id,
      settSelector: 'ignition_detection',
      properties: {
        ign: ignObject
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('ignitionDetectionSourcePayload', ignitionDetectionSourcePayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(ignitionDetectionSourcePayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated ignition detection source settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update ignition detection source settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors are', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleIgnitionDetectionSetting)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Ignition Detection Source</Typography>
                <Typography className={classes.secondaryHeading}>
                  Ignition source is configurable and used to determine vehicle ignition status. Ignition status is used
                  in power management and the following functionalities: eco driving, excessive idling, fuel
                  consumption, over speeding, towing and trip.
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <div>
              <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Ignition Detection Source</Typography>

              <Controller
                control={control}
                name="source"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="source"
                    options={ignitionDetectionSourceOptions}
                    value={ignitionDetectionSourceOptions.find((option) => option.id === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                  />
                )}
              />
            </div>

            {/* TODO to add this two textfield movement start delay seconds movement stop delay seconds */}
            {watchSource !== 1 && watchSource !== 8 && watchSource !== 9 ? (
              <div style={{ marginTop: '1rem' }}>
                {/* textfield high, low,  do not show this while showing for start stop */}
                {watchSource !== 2 && watchSource !== 3 && watchSource !== 10 && watchSource !== 11 ? (
                  <div>
                    <Controller
                      control={control}
                      name="high"
                      render={({ field }) => (
                        <TextField
                          required
                          variant="outlined"
                          size="small"
                          {...field}
                          className={classes.textField}
                          autoFocus
                          id="high"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">mv</InputAdornment>
                          }}
                          name="high"
                          type="number"
                          style={{ marginBottom: '1rem' }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="low"
                      render={({ field }) => (
                        <TextField
                          required
                          variant="outlined"
                          size="small"
                          {...field}
                          className={classes.textField}
                          autoFocus
                          id="low"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">mv</InputAdornment>
                          }}
                          name="low"
                          type="number"
                          style={{ marginBottom: '1rem' }}
                        />
                      )}
                    />
                  </div>
                ) : null}

                {/* textfield for start, stop , do not show this while showing for high low for  */}
                {watchSource !== 4 && watchSource !== 5 && watchSource !== 12 && watchSource !== 13 ? (
                  <div>
                    <Controller
                      control={control}
                      name="start"
                      render={({ field }) => (
                        <TextField
                          required
                          variant="outlined"
                          size="small"
                          {...field}
                          className={classes.textField}
                          autoFocus
                          id="start"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                          }}
                          name="start"
                          type="number"
                          style={{ marginBottom: '1rem' }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="stop"
                      render={({ field }) => (
                        <TextField
                          required
                          variant="outlined"
                          size="small"
                          {...field}
                          className={classes.textField}
                          autoFocus
                          id="stop"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                          }}
                          name="stop"
                          type="number"
                          style={{ marginBottom: '1rem' }}
                        />
                      )}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default IgnitionDetectionSource;

IgnitionDetectionSource.propTypes = {
  ignitionDetectionSourceRecord: PropTypes.array
};
