import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      paddingLeft: '1rem'
    },
    marginBottom: '1rem',
    border: '1px dashed #757575',
    paddingLeft: '1rem'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    marginBottom: '1rem'
  }
}));

const protocolOptions = [
  { value: 'tcp', label: 'TCP', id: 0 },
  { value: 'udp', label: 'After ignition on', id: 1 }
];

const GPRSServerSetup = ({ gprsServerSetupRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const selectedProtocol = protocolOptions.find((option) => option.id === gprsServerSetupRecord[0]?.current?.protocol);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordionSummary}
        >
          <div className={classes.accordionSummaryDiv}>
            <div className={classes.settingsDiv}>
              <SettingsIcon />
            </div>

            <div>
              <Typography className={classes.heading}>GPRS Server Setup</Typography>
              <Typography className={classes.secondaryHeading}>
                Setup server to send data by GPRS link. Note: after changing this settings device will be disconnected
                from current server.
              </Typography>
            </div>
          </div>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={classes.accordionDetails}>
          <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>GPRS Server Setup</Typography>

          <div className={classes.accordionDetailsItem}>
            <TextField
              label="Host"
              size="small"
              value={gprsServerSetupRecord.length > 0 ? gprsServerSetupRecord[0]?.current?.host : ''}
              className={classes.root}
              autoFocus
              id="host"
              name="host"
              type="text"
              disabled
            />

            <TextField
              label="Port"
              size="small"
              value={gprsServerSetupRecord.length > 0 ? gprsServerSetupRecord[0]?.current?.port : ''}
              className={classes.root}
              autoFocus
              id="port"
              name="port"
              type="text"
              disabled
            />

            <TextField
              label="Protocol"
              size="small"
              value={selectedProtocol ? selectedProtocol.label : ''}
              className={classes.root}
              autoFocus
              id="protocol"
              name="protocol"
              type="text"
              disabled
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GPRSServerSetup;

GPRSServerSetup.propTypes = {
  gprsServerSetupRecord: PropTypes.array
};
