import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import StarBorder from '@mui/icons-material/StarBorder';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  listItem: {
    '&:hover': {
      color: 'white'
    },
    borderLeft: (props) => (props.selected ? '4px solid green' : ''),
    color: (props) => (props.selected ? 'white' : '#8898aa')
  },
  listItemIcon: {
    color: (props) => (props.selected ? 'white' : '#8898aa'),
    minWidth: '25px'
  }
}));

const SideNavItem = ({
  selectedItem,
  mobileOpen,
  handleListItemClick,
  handleLargeDevice,
  primaryText,
  icon,
  expandNestedItem,
  to
}) => {
  const isSelected = selectedItem === primaryText;
  const classes = useStyles({ selected: isSelected });
  const assetNestedItem = ['Asset', 'Maps', 'Beacon', 'Tracker'];
  const timesheetNestedItem = ['Timesheets', 'BillLogs'];

  const nestedItemList =
    selectedItem === primaryText && primaryText === 'Timesheets and Logs'
      ? timesheetNestedItem
      : selectedItem === primaryText && primaryText === 'Asset'
      ? assetNestedItem
      : [];

  return (
    <div>
      {primaryText === 'Asset' ? (
        <div>
          <ListItem
            button
            onClick={mobileOpen ? () => handleListItemClick(primaryText) : () => handleLargeDevice(primaryText)}
            className={classes.listItem}
            selected={isSelected}
          >
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
            <ListItemText primary={primaryText} />
            {expandNestedItem.asset ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={primaryText === 'Asset' && expandNestedItem.asset} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedItemList?.map((item) => (
                <Link key={item} to={'/' + item.toLocaleLowerCase()}>
                  <ListItem button style={{ paddingLeft: '32px' }} className={classes.listItem}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </div>
      ) : primaryText === 'Timesheets and Logs' ? (
        <div>
          <ListItem
            button
            onClick={mobileOpen ? () => handleListItemClick(primaryText) : () => handleLargeDevice(primaryText)}
            className={classes.listItem}
            selected={isSelected}
          >
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
            <ListItemText primary={primaryText} />
            {expandNestedItem.timesheetAndLogs ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            in={primaryText === 'Timesheets and Logs' && expandNestedItem.timesheetAndLogs}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {nestedItemList?.map((item) => (
                <Link key={item} to={'/' + item.toLocaleLowerCase()}>
                  <ListItem button style={{ paddingLeft: '32px' }} className={classes.listItem}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </div>
      ) : (
        <Link to={to}>
          <ListItem
            button
            onClick={mobileOpen ? () => handleListItemClick(primaryText) : () => handleLargeDevice(primaryText)}
            className={classes.listItem}
            selected={isSelected}
          >
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
            <ListItemText primary={primaryText} />
          </ListItem>
        </Link>
      )}
    </div>
  );
};

export default SideNavItem;

SideNavItem.propTypes = {
  primaryText: PropTypes.string,
  mobileOpen: PropTypes.bool,
  handleListItemClick: PropTypes.func,
  role: PropTypes.string,
  selectedItem: PropTypes.string,
  handleLargeDevice: PropTypes.func,
  icon: PropTypes.any,
  expandNestedItem: PropTypes.shape({
    timesheetAndLogs: PropTypes.bool,
    asset: PropTypes.bool
  }),
  to: PropTypes.string
};
