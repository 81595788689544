import PropTypes from 'prop-types';
import React from 'react';
import Calendar from '../../VehicleTracker/vehicleTrackerLeftBar/vehicleTrackerTopBar/Calendar';
import RouteTab from '../../VehicleTracker/vehicleTrackerLeftBar/vehicleTrackerTopBar/tabs/RouteTab';
import BeaconRouteTab from '../../VehicleTracker/vehicleTrackerLeftBar/vehicleTrackerTopBar/tabs/BeaconRouteTab';
import { Button, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  routeWrapper: {
    paddingTop: '1rem'
  },
  backButton: {
    paddingBottom: '1rem'
  }
});

const Route = ({
  clickedDate,
  deviceId,
  beaconMac,
  setClickedDate,
  selectedBeaconInstanceId,
  setAssetItemClicked,
  setDeviceId,
  setBeaconMac
}) => {
  const classes = useStyles();
  const handleBackButton = () => {
    setAssetItemClicked(false);
    setDeviceId(null);
    setBeaconMac(null);

    let todayDate = new Date();
    todayDate = todayDate.toLocaleDateString();
    todayDate = todayDate.split('/').reverse().join('-');
    setClickedDate(todayDate);
  };

  return (
    <div className={classes.routeWrapper}>
      <div className={classes.backButton} onClick={handleBackButton}>
        <Button startIcon={<ArrowBackIcon />}>Back</Button>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <Calendar clickedDate={clickedDate} setClickedDate={setClickedDate} />
      </div>
      {deviceId ? (
        <RouteTab deviceId={deviceId} dateString={clickedDate} />
      ) : beaconMac && selectedBeaconInstanceId ? (
        <BeaconRouteTab beaconInstanceID={selectedBeaconInstanceId} dateString={clickedDate} />
      ) : null}
    </div>
  );
};

export default Route;

Route.propTypes = {
  clickedDate: PropTypes.any,
  deviceId: PropTypes.any,
  beaconMac: PropTypes.any,
  setClickedDate: PropTypes.any,
  selectedBeaconInstanceId: PropTypes.any,
  setAssetItemClicked: PropTypes.func,
  setDeviceId: PropTypes.func,
  setBeaconMac: PropTypes.func
};
