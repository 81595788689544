import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import ListItemDetails from './ListItemDetails';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  listWrapper: {
    // height: '893px',
    overflowY: 'auto'
  }
});

const MyAssetList = ({ employeeMapAssets, assetMapPage, assetMapRowsPerPage, handleClickedMapItem }) => {
  const classes = useStyles();

  // Slice data for current page
  const paginatedRecords = employeeMapAssets?.slice(
    assetMapPage * assetMapRowsPerPage,
    assetMapPage * assetMapRowsPerPage + assetMapRowsPerPage
  );

  return (
    <div className={classes.listWrapper}>
      <List component="nav" aria-label="main vehicle tracker folders">
        {employeeMapAssets.length > 0 && paginatedRecords.length > 0 ? (
          paginatedRecords?.map((record, index) => {
            return (
              <div key={record.deviceName + index}>
                <Divider />
                <ListItem
                  button
                  onClick={() => handleClickedMapItem(record.deviceName, record.deviceId, record.beaconMac)}
                >
                  <ListItemIcon>{record.beaconMac ? <BluetoothIcon /> : <DriveEtaIcon />}</ListItemIcon>
                  <ListItemDetails record={record} />
                </ListItem>
                <Divider />
              </div>
            );
          })
        ) : (
          <>
            <Divider />
            <p>No device found</p>
            <Divider />
          </>
        )}
      </List>
    </div>
  );
};

export default MyAssetList;

MyAssetList.propTypes = {
  employeeMapAssets: PropTypes.any,
  assetMapRowsPerPage: PropTypes.number,
  assetMapPage: PropTypes.number,
  handleClickedMapItem: PropTypes.func
};
