import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableSelection, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import PropTypes from 'prop-types';
import BeaconDetailDialog from '../dialog/BeaconDetailDialog';

const DetailProvider = (props) => {
  return <DataTypeProvider formatterComponent={BeaconDetailDialog} {...props} />;
};

const getRowId = (row) => row.beaconDBId;

const BeaconTable = ({ rows }) => {
  const [columns] = useState([
    { name: 'name', title: 'Name' },
    { name: 'rssi', title: 'RSSI' },
    { name: 'mac', title: 'Mac Address' },
    {
      name: 'detail',
      title: 'Details',
      getCellValue: (row) => ({
        mac: row.mac,
        protocol: row.protocol,
        power: row.power,
        NamespaceID: row.NamespaceID,
        updatedAt: row.updatedAt,
        name: row.name,
        InstanceID: row.InstanceID,
        UUID: row.UUID
      })
    }
  ]);
  const [selection, setSelection] = useState([]);
  const [detailColumn] = useState(['detail']);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100]);

  return (
    <div>
      <span>
        Total rows selected:
        {selection.length}
      </span>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <DetailProvider for={detailColumn} />
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <IntegratedSelection />
          <Table />
          <TableHeaderRow />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    </div>
  );
};

export default BeaconTable;

BeaconTable.propTypes = {
  rows: PropTypes.array
};
