import React, { useEffect, useState } from 'react';
import vehicleTrackerService from '../../../../../services/VehicleTrackerService';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { formatDuration, getLocalTimeString } from '../../../helper/helper';
import { parseISO, startOfDay, endOfDay, getUnixTime, isToday, subHours } from 'date-fns';

import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}));

const RouteTab = ({ deviceId, dateString }) => {
  const classes = useStyles();
  const [routeHistory, setRouteHistory] = useState();
  const [steps, setSteps] = useState([]);
  const [stepContent, setStepContent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDailyRouteHistory = async () => {
      try {
        setLoading(true);
        // Parse the date string
        const date = parseISO(dateString);
        // Get the start and end of the day
        let fromTimestamp = getUnixTime(startOfDay(date)); // 12:00 AM timestamp
        let toTimestamp = getUnixTime(endOfDay(date)); // 11:59 PM timestamp

        //if employee and date is today then subtract 12hours
        //if admin don't need to subtract
        // eslint-disable-next-line no-undef
        const employeeRole = localStorage.getItem('role');

        // If it's today, lag the timestamps by 12 hours
        if (isToday(new Date(dateString)) && employeeRole === 'User') {
          fromTimestamp = getUnixTime(subHours(startOfDay(date), 12)); // 12 hours lagged
          toTimestamp = getUnixTime(subHours(new Date(), 12)); // Subtract 12 hours from the current time
        }

        const vehiclePayload = {
          deviceId: deviceId,
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp
        };

        const routeHistory = await vehicleTrackerService.getTelemetryDailyDuration(vehiclePayload);
        const routeHistoryRecord = routeHistory?.data?.data;

        if (routeHistoryRecord.length > 0) {
          const stepDetails = [];
          const addressPromises = [];

          // Storing positions to fetch addresses sequentially
          let positions = [];
          routeHistoryRecord?.forEach((record) => {
            stepDetails.push({
              dailyMileage: `${record.mileage.toFixed(2)}km`,
              duration: formatDuration(record.duration),
              time: {
                begin: getLocalTimeString(record.begin),
                end: getLocalTimeString(record.end)
              }
            });

            record.positions.forEach((position) => {
              positions.push(position); // Add each position to an array
            });
          });

          setRouteHistory(routeHistoryRecord);
          setStepContent(stepDetails);

          let index = 0;

          // eslint-disable-next-line no-undef
          const fetchAddressInterval = setInterval(() => {
            if (index < positions.length) {
              const position = positions[index];

              axios
                .get(
                  `https://nominatim.openstreetmap.org/reverse?lat=${position.latitude}&lon=${position.longitude}&format=json`
                )
                .then((response) => {
                  const data = response.data;
                  addressPromises.push(
                    data.name ||
                      data.display_name ||
                      `No address found at (${position.latitude}, ${position.longitude})`
                  );
                })
                .catch(() => {
                  addressPromises.push(`Error fetching address at (${position.latitude}, ${position.longitude})`);
                });

              index++; // Move to the next position
            } else {
              // Once all positions are processed, clear the interval
              // eslint-disable-next-line no-undef
              clearInterval(fetchAddressInterval);

              // Once all address fetches are done, update the state
              setSteps(addressPromises);
            }
          }, 2000); // 2000ms delay (2 seconds) between each fetch}
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDailyRouteHistory();
  }, [dateString, deviceId]);

  return (
    <div className={classes.root}>
      {loading || !steps.length ? (
        <div>
          <Skeleton variant="div" height={90} style={{ marginBottom: '10px' }} />
          <Skeleton variant="div" height={90} style={{ marginBottom: '10px' }} />

          <Skeleton variant="div" height={90} style={{ marginBottom: '10px' }} />
          <Skeleton variant="div" height={90} style={{ marginBottom: '10px' }} />
        </div>
      ) : routeHistory?.length > 0 && steps.length > 0 ? (
        <Stepper orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label + index} active={steps[index / 2] ? true : false}>
              <StepLabel>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <span>{label}</span>
                  {index % 2 === 0 && stepContent[index / 2]?.duration && (
                    <span>{stepContent[index / 2]?.duration}</span>
                  )}
                </div>
              </StepLabel>

              <StepContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ marginRight: '4px', fontSize: '14px' }}>
                      {stepContent[index / 2]?.time?.begin || 'N/A'}
                    </Typography>
                    <span> - </span>
                    <Typography style={{ marginLeft: '4px', fontSize: '14px' }}>
                      {stepContent[index / 2]?.time?.end || 'N/A'}
                    </Typography>
                  </div>
                  <Typography style={{ fontSize: '14px' }}>{stepContent[index / 2]?.dailyMileage || 'N/A'}</Typography>
                </div>
                {/* )} */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        !loading && !routeHistory?.length && <div>{`No data to display on the given date ${dateString}`}</div>
      )}
    </div>
  );
};

export default RouteTab;

RouteTab.propTypes = {
  deviceId: PropTypes.any,
  dateString: PropTypes.any
};
