import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Switch,
  makeStyles,
  Divider,
  AccordionActions,
  Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },

  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  }
}));

const staticNavigationOptions = [
  { value: 'movement', label: 'Movement', id: 1 },
  { value: 'ignition', label: 'Ignition', id: 2 },
  { value: 'movementOrIgnition', label: 'Movement or Ignition', id: 3 }
];

const StaticNavigation = ({ staticNavigationRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      enable: staticNavigationRecord[0]?.current?.stat_nav?.enable,
      source: staticNavigationRecord[0]?.current?.stat_nav?.source
    }
  });

  const staticNavigationEnable = watch('enable');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStaticNavigationSetting = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('setting changed data', data);

    setLoading(true);

    let staticNavigationPayload;
    if (!data.enable) {
      staticNavigationPayload = {
        deviceId: staticNavigationRecord[0]?.device_id,
        settSelector: 'static_filter',
        properties: {
          stat_nav: {
            enable: data.enable
          }
        }
      };
    }
    if (data.enable) {
      staticNavigationPayload = {
        deviceId: staticNavigationRecord[0]?.device_id,
        settSelector: 'static_filter',
        properties: {
          stat_nav: {
            enable: data.enable,
            source: data.source
          }
        }
      };
    }

    // eslint-disable-next-line no-console, no-undef
    console.log('staticNavigationPayload', staticNavigationPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(staticNavigationPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated static navigation settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update static navigation settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleStaticNavigationSetting)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Static Navigation</Typography>
                <Typography className={classes.secondaryHeading}>
                  Static Navigation Mode is a filter, which filters out track jumps when the object is not moving
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={{ color: '#9e9e9e ' }}>Static Navigation Source</Typography>
              <div>
                <Controller
                  control={control}
                  name="enable"
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                      name="enable"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  )}
                />
                <HelpOutlinedIcon
                  titleAccess="Enable or disable static navigation mode"
                  color="disabled"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>

            {staticNavigationEnable && (
              <div>
                <Typography>On</Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <div style={{ flex: '1' }}>
                    <Controller
                      control={control}
                      name="source"
                      render={({ field }) => (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="source"
                          options={staticNavigationOptions}
                          value={staticNavigationOptions?.find((option) => option.id === field.value)}
                          onChange={(selected) => field.onChange(selected ? selected.id : null)}
                        />
                      )}
                    />
                  </div>

                  <HelpOutlinedIcon
                    titleAccess="Source that is used to determine whether vehicle is moving to activate/deactivate static navigation. Depends on [Motion Detection Source] and [Ignition Detection Source] settings."
                    color="disabled"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            )}
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default StaticNavigation;

StaticNavigation.propTypes = {
  staticNavigationRecord: PropTypes.array
};
