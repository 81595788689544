import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  makeStyles,
  Switch,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const GNSSSource = ({ gnssSource }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      beidou: gnssSource[0]?.current?.beidou,
      galileo: gnssSource[0]?.current?.galileo,
      glonass: gnssSource[0]?.current?.glonass,
      gps: gnssSource[0]?.current?.gps
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGNSSSourceSetting = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('data is:', data);
    setLoading(true);

    const gnssSourcePayload = {
      deviceId: gnssSource[0]?.device_id,
      settSelector: 'gnss_source',
      properties: {
        beidou: data.beidou,
        galileo: data.galileo,
        glonass: data.glonass,
        gps: data.gps
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(gnssSourcePayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated gnss_source settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update gnss_source settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleGNSSSourceSetting)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> GNSS Source </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e ', marginBottom: '10px' }}>GNSS Source</Typography>

            <div className={classes.accordionDetailsItem}>
              <label>Beidou</label>
              <Controller
                control={control}
                name="beidou"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                    name="beidou"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>Glonass</label>
              <Controller
                control={control}
                name="glonass"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                    name="glonass"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>Galileo</label>
              <Controller
                control={control}
                name="galileo"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                    name="galileo"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <label>GPS</label>
              <Controller
                control={control}
                name="gps"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                    name="gps"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default GNSSSource;

GNSSSource.propTypes = {
  gnssSource: PropTypes.array
};
