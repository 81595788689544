import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: '1rem'
  }
}));

const odometerOptions = [
  { value: 'gnss', label: 'GNSS', id: 0 },
  { value: 'obd', label: 'OBD', id: 1 },
  { value: 'lvcan', label: 'LVCAN', id: 2 }
];

const Odometer = ({ odometerRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      source: odometerRecord[0]?.current?.source,
      value: odometerRecord[0]?.current?.value
    }
  });

  const handleOdometer = (data) => {
    setLoading(true);

    const odometerPayload = {
      deviceId: odometerRecord[0]?.device_id,
      settSelector: 'odometer_fmb',
      properties: {
        source: data.source,
        value: data.value
      }
    };

    // eslint-disable-next-line no-console, no-undef
    console.log('odometerPayload', odometerPayload);

    flespiDeviceService
      .updateFlespiDeviceSetting(odometerPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated odometer.');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update odometer');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleOdometer)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Odometer</Typography>
                <Typography className={classes.secondaryHeading}>Set odometer mode and initial value</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Odometer</Typography>
            <div className={classes.accordionDetailsItem}>
              <Controller
                control={control}
                name="source"
                render={({ field }) => (
                  <>
                    <label htmlFor="source-select" style={{ marginBottom: '-14px', color: 'gray', fontSize: '12px' }}>
                      Calculation source
                    </label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      isSearchable={true}
                      name="source"
                      options={odometerOptions}
                      value={odometerOptions.find((option) => option.id === field.value)}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    />
                  </>
                )}
              />

              <Controller
                control={control}
                name="value"
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    size="small"
                    {...field}
                    autoFocus
                    id="value"
                    name="value"
                    type="number"
                    style={{ marginBottom: '1rem' }}
                    label="Initiate odometer value"
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> km</InputAdornment>
                    }}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={Object.keys(errors).length > 0 || loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default Odometer;

Odometer.propTypes = {
  odometerRecord: PropTypes.array
};
