import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const modeOptions = [
  { value: 'none', label: 'None', id: 0 },
  { value: 'handsFree', label: 'Hands Free', id: 1 },
  { value: 'obdii', label: 'OBDII', id: 2 },
  { value: 'dataLink', label: 'Data Link', id: 3 },
  { value: 'inateckScanner', label: 'Inateck Scanner', id: 4 },
  { value: 'userId', label: 'User ID', id: 5 }
];

const AutoConnectToExternalDevice = ({ currentRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      external_mac: currentRecord[0]?.current?.external_mac,
      external_name: currentRecord[0]?.current?.external_name,
      external_pin: currentRecord[0]?.current?.external_pin,
      mode: currentRecord[0]?.current?.mode
    }
  });

  const handleAutoConnectToExternalDevice = (data) => {
    setLoading(true);

    let autoConnectToExternalDevicePayload = {
      deviceId: currentRecord[0]?.device_id,
      settSelector: 'bluetooth_auto_connect',
      properties: {
        external_mac: data.external_mac,
        external_name: data.external_name,
        external_pin: data.external_pin,
        mode: data.mode
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(autoConnectToExternalDevicePayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success(`Successfully updated auto connect to external device settings`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || `Unable to update auto connect to external device settings`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleAutoConnectToExternalDevice)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Auto connect to external device</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}> Auto connect to external device</Typography>

            {/* mode */}
            <div>
              <Typography style={{ color: '#9e9e9e', marginBottom: '10px', fontSize: '14px' }}>
                Connection mode
              </Typography>

              <Controller
                name="mode"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue=""
                    isSearchable={true}
                    name="mode"
                    options={modeOptions}
                    value={modeOptions.find((option) => option.id === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
            </div>

            {/* external_mac */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="external_mac"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="External Mac"
                    name="external_mac"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.external_mac}
                    helperText={errors.external_mac?.message}
                  />
                )}
              />
            </div>

            {/* external_name */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="external_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="External name"
                    name="external_name"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.external_name}
                    helperText={errors.external_name?.message}
                  />
                )}
              />
            </div>

            {/* external_pin */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="external_pin"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="External PIN"
                    name="external_pin"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.external_pin}
                    helperText={errors.external_pin?.message}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default AutoConnectToExternalDevice;

AutoConnectToExternalDevice.propTypes = {
  currentRecord: PropTypes.array
};
