import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { parseISO, startOfDay, endOfDay, getUnixTime, isToday, subHours } from 'date-fns';
import { makeStyles, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';

import bluetoothToolService from '../../../../../services/BluetoothToolService';
import { getLocalTimeString } from '../../../helper/helper';

import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(() => ({
  stepContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  stepContentDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  typography: {
    marginRight: '4px',
    fontSize: '14px'
  }
}));

const BeaconRouteTab = ({ beaconInstanceID, dateString }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [beaconRouteRecord, setBeaconRouteRecord] = useState([]);
  const [steps, setSteps] = useState([]);
  const [stepContent, setStepContent] = useState([]);

  useEffect(() => {
    const fetchBeaconRouteHistory = async () => {
      try {
        setLoading(true);
        // Parse the date string
        const date = parseISO(dateString);
        // Get the start and end of the day
        let fromTimestamp = getUnixTime(startOfDay(date)); // 12:00 AM timestamp
        let toTimestamp = getUnixTime(endOfDay(date)); // 11:59 PM timestamp

        //if employee type user and date is today then subtract 12hours
        //if employee type admin don't need to subtract
        // eslint-disable-next-line no-undef
        const employeeRole = localStorage.getItem('role');

        // If it's today, lag the timestamps by 12 hours
        if (isToday(new Date(dateString)) && employeeRole === 'User') {
          fromTimestamp = getUnixTime(subHours(startOfDay(date), 12)); // 12 hours lagged
          toTimestamp = getUnixTime(subHours(new Date(), 12)); // Subtract 12 hours from the current time
        }

        const beaconPayload = {
          beaconInstanceID: beaconInstanceID,
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp
        };
        const routeHistoryResponse = await bluetoothToolService.getBeaconRouteHistory(beaconPayload);
        // eslint-disable-next-line no-console, no-undef
        console.log('routeHistoryResponse', routeHistoryResponse);

        const routeHistory = routeHistoryResponse?.data?.data;
        // eslint-disable-next-line no-console, no-undef
        console.log('routeHistory', routeHistory);

        const stepsRecords = routeHistory?.map((rHistory) => rHistory.location);
        setSteps(stepsRecords);

        const stepContentRecords = routeHistory?.map((rHistory) => {
          return { time: getLocalTimeString(rHistory.timestamp) };
        });
        setStepContent(stepContentRecords);

        setBeaconRouteRecord(routeHistory);
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBeaconRouteHistory();
  }, [beaconInstanceID, dateString]);

  // demo data for local
  // const routeHistory = [
  //   {
  //     _id: '677e76f3a67d3c0011ab24e6',
  //     protocol: 'Eddystone',
  //     rssi: -78,
  //     timestamp: 1736341228.011,
  //     position: {
  //       latitude: -37.798473,
  //       longitude: 144.89604
  //     },
  //     location: 'Barkly Place, Footscray, Melbourne, City of Maribyrnong, Victoria, 3011, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'EA7F8F8DF0C855AA952A',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T13:00:35.832Z',
  //     updatedAt: '2025-01-08T13:00:35.832Z',
  //     __v: 0
  //   },
  //   {
  //     _id: '677e78daa67d3c0011ab750f',
  //     protocol: 'Eddystone',
  //     rssi: -58,
  //     timestamp: 1736345868.011,
  //     position: {
  //       latitude: -37.847848,
  //       longitude: 144.871245
  //     },
  //     location: '15, Holloway Street, Newport, Melbourne, City of Hobsons Bay, Victoria, 3015, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'CAC78F8DF0C855AA7572',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T13:08:42.181Z',
  //     updatedAt: '2025-01-08T14:18:50.674Z',
  //     __v: 0
  //   },
  //   {
  //     _id: '677e89c1a67d3c0011ad5745',
  //     protocol: 'Eddystone',
  //     rssi: -77,
  //     timestamp: 1736346037.011,
  //     position: {
  //       latitude: -37.798585,
  //       longitude: 144.895727
  //     },
  //     location:
  //       'Footscray High School Barkly Campus, 268-280, Barkly Street, Footscray, Melbourne, City of Maribyrnong, Victoria, 3011, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'F3F78F8DF0C855AA9EA2',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T14:20:49.194Z',
  //     updatedAt: '2025-01-08T14:20:49.194Z',
  //     __v: 0
  //   },
  //   {
  //     _id: '677e8ba2a67d3c0011ada7d5',
  //     protocol: 'Eddystone',
  //     rssi: -52,
  //     timestamp: 1736363299.011,
  //     position: {
  //       latitude: -37.847848,
  //       longitude: 144.871245
  //     },
  //     location: '15, Holloway Street, Newport, Melbourne, City of Hobsons Bay, Victoria, 3015, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'CAC78F8DF0C855AA7572',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T14:28:50.700Z',
  //     updatedAt: '2025-01-08T19:09:21.031Z',
  //     __v: 0
  //   },
  //   {
  //     _id: '677ed1dda67d3c0011b6ba15',
  //     protocol: 'Eddystone',
  //     rssi: -51,
  //     timestamp: 1736364501.011,
  //     position: {
  //       latitude: -37.80416,
  //       longitude: 144.882945
  //     },
  //     location: 'Geelong Street, Kingsville, Melbourne, City of Maribyrnong, Victoria, 3012, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'CAC78F8DF0C855AA7572',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T19:28:29.457Z',
  //     updatedAt: '2025-01-08T19:28:29.457Z',
  //     __v: 0
  //   },
  //   {
  //     _id: '677ed435a67d3c0011b709c2',
  //     protocol: 'Eddystone',
  //     rssi: -55,
  //     timestamp: 1736367507.011,
  //     position: {
  //       latitude: -37.798553,
  //       longitude: 144.895988
  //     },
  //     location: 'Barkly Place, Footscray, Melbourne, City of Maribyrnong, Victoria, 3011, Australia',
  //     InstanceID: '3A389B730055',
  //     NamespaceID: 'CAC78F8DF0C855AA7572',
  //     UUID: 'AAFE',
  //     createdAt: '2025-01-08T19:38:29.670Z',
  //     updatedAt: '2025-01-08T20:19:19.011Z',
  //     __v: 0
  //   }
  // ];

  // const steps = routeHistory.map((rHistory) => rHistory.location);
  // const stepContent = routeHistory.map((rHistory) => {
  //   return { time: getLocalTimeString(rHistory.timestamp) };
  // });

  return (
    <div>
      {loading ? (
        <div>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </div>
      ) : (
        <div>
          {steps.length > 0 ? (
            <Stepper orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label + index} active={true}>
                  <StepLabel>{label}</StepLabel>

                  <StepContent>
                    <div className={classes.stepContentWrapper}>
                      <div className={classes.stepContentDiv}>
                        <Typography className={classes.typography}>{stepContent[index]?.time}</Typography>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <div>
              <Typography variant="body1" style={{ padding: '1.5rem 0', textAlign: 'center' }}>
                No route history to show.
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BeaconRouteTab;

BeaconRouteTab.propTypes = {
  beaconInstanceID: PropTypes.any,
  dateString: PropTypes.any
};
