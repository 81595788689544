import React, { useState } from 'react';
import MyAssetLeftBar from './myAssetLeftBar/MyAssetLeftBar';
import MyAssetRightBar from './myAssetRightBar/MyAssetRightBar';
import useFetchEmployeeMapAssetRecord from '../MyAsset/hooks/useFetchEmployeeMapAssetRecord';

const MyAssetMap = () => {
  const { employeeMapAssets } = useFetchEmployeeMapAssetRecord();

  const [assetItemClicked, setAssetItemClicked] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [beaconMac, setBeaconMac] = useState(null);

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const [clickedDate, setClickedDate] = useState(todayDate);

  const handleClickedMapItem = (deviceName, deviceId, beaconMac) => {
    setAssetItemClicked(true);
    //for tracker assets
    if (deviceId) {
      setDeviceId(deviceId);
      setBeaconMac(null);
    }
    //for beacon assets
    if (beaconMac) {
      setBeaconMac(beaconMac);
      setDeviceId(null);
    }
  };

  const selectedBeaconInstanceId =
    beaconMac && employeeMapAssets.filter((asset) => asset.beaconMac === beaconMac)?.beaconInstanceID;

  return (
    <div style={{ display: 'flex' }}>
      <MyAssetLeftBar
        employeeMapAssets={employeeMapAssets}
        handleClickedMapItem={handleClickedMapItem}
        setAssetItemClicked={setAssetItemClicked}
        assetItemClicked={assetItemClicked}
        clickedDate={clickedDate}
        deviceId={deviceId}
        beaconMac={beaconMac}
        setClickedDate={setClickedDate}
        selectedBeaconInstanceId={selectedBeaconInstanceId}
        setDeviceId={setDeviceId}
        setBeaconMac={setBeaconMac}
      />
      <MyAssetRightBar
        deviceId={deviceId}
        dateString={clickedDate}
        beaconMac={beaconMac}
        employeeMapAssets={employeeMapAssets}
        handleClickedMapItem={handleClickedMapItem}
      />
    </div>
  );
};

export default MyAssetMap;
