import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import PropTypes from 'prop-types';

const TrackerList = ({ trackerRecord, setTrackerItemClicked, setDeviceId }) => {
  const handleTrackerItemClicked = (deviceId) => {
    setTrackerItemClicked(true);
    setDeviceId(deviceId);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <List>
        {trackerRecord.map((record) => (
          <ListItem key={record.deviceId} onClick={() => handleTrackerItemClicked(record.deviceId)}>
            <ListItemAvatar>
              <Avatar>
                <DeveloperBoardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={record.deviceName} secondary={record.deviceId} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default TrackerList;

TrackerList.propTypes = {
  trackerRecord: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      deviceId: PropTypes.number
    })
  ),
  setTrackerItemClicked: PropTypes.func,
  setDeviceId: PropTypes.func
};
