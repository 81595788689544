import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },

  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    marginBottom: '1rem'
  }
}));

const FirmwareOverAirWebService = ({ firmwareRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordionSummary}
        >
          <div className={classes.accordionSummaryDiv}>
            <div className={classes.settingsDiv}>
              <SettingsIcon />
            </div>

            <div>
              <Typography className={classes.heading}>Firmware Over Air Web Service</Typography>
            </div>
          </div>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={classes.accordionDetails}>
          <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Firmware Over Air Web Service</Typography>

          <div className={classes.accordionDetailsItem}>
            <TextField
              label="Host"
              variant="outlined"
              size="small"
              value={firmwareRecord.length > 0 ? firmwareRecord[0]?.current?.web?.host : ''}
              className={classes.textField}
              autoFocus
              id="host"
              name="host"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="Period"
              variant="outlined"
              size="small"
              value={firmwareRecord.length > 0 ? firmwareRecord[0]?.current?.web?.period : ''}
              className={classes.textField}
              autoFocus
              id="period"
              name="period"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="Port"
              variant="outlined"
              size="small"
              value={firmwareRecord.length > 0 ? firmwareRecord[0]?.current?.web?.port : ''}
              className={classes.textField}
              autoFocus
              id="port"
              name="port"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FirmwareOverAirWebService;

FirmwareOverAirWebService.propTypes = {
  firmwareRecord: PropTypes.array
};
