import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { makeStyles } from '@material-ui/core';

import MapVehicleItem from '../../VehicleTracker/vehicleTrackerRightBar/MapVehicleItem';
import PropTypes from 'prop-types';
import TrackerMapComponent from '../../VehicleTracker/vehicleTrackerRightBar/mapComponent/TrackerMapComponent';
import BeaconMapComponent from '../../VehicleTracker/vehicleTrackerRightBar/mapComponent/BeaconMapComponent';

const useStyles = makeStyles({
  leafletInteractive: {
    strokeWidth: 5,
    stroke: '#03a9f4'
  },
  mapComponentWrapper: {
    width: '100%'
  }
});

const MapComponent = ({ deviceId, dateString, beaconMac, handleClickedMapItem, employeeMapAssets }) => {
  const classes = useStyles();

  const latitude = -37.79869;
  const longitude = 144.89608;
  const position = [latitude, longitude];
  const [vehicleTabClicked] = useState('route');

  const filteredTrackerRecords = deviceId
    ? employeeMapAssets?.filter((record) => record.deviceId === deviceId)
    : beaconMac
    ? employeeMapAssets?.filter((record) => record.beaconMac === beaconMac)
    : employeeMapAssets;

  return (
    <div style={{ display: 'flex', flex: '1' }}>
      <div className={classes.mapComponentWrapper}>
        {deviceId ? (
          <TrackerMapComponent
            position={position}
            deviceId={deviceId}
            vehicleTabClicked={vehicleTabClicked}
            dateString={dateString}
          />
        ) : beaconMac ? (
          <BeaconMapComponent
            position={position}
            beaconInstanceID={filteredTrackerRecords[0]?.beaconInstanceID}
            vehicleTabClicked={vehicleTabClicked}
            dateString={dateString}
            beaconMac={beaconMac}
          />
        ) : (
          <MapContainer center={position} zoom={13} style={{ height: '100vh', width: '100%' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {filteredTrackerRecords?.length > 0
              ? filteredTrackerRecords.map((vehicleTrackerItem) => (
                  <MapVehicleItem
                    key={vehicleTrackerItem.deviceName || vehicleTrackerItem.deviceId || vehicleTrackerItem.beaconMac}
                    vehicleTrackerItem={vehicleTrackerItem}
                    handleClickedMapItem={handleClickedMapItem}
                  />
                ))
              : null}
          </MapContainer>
        )}
        <MapContainer center={position} zoom={13} style={{ height: '100vh', width: '100%' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {employeeMapAssets?.length > 0
            ? employeeMapAssets.map((vehicleTrackerItem) => (
                <MapVehicleItem
                  key={vehicleTrackerItem.deviceId || vehicleTrackerItem.beaconMac || vehicleTrackerItem.deviceName}
                  vehicleTrackerItem={vehicleTrackerItem}
                  handleClickedMapItem={handleClickedMapItem}
                />
              ))
            : null}
        </MapContainer>
      </div>
    </div>
  );
};

export default MapComponent;

MapComponent.propTypes = {
  deviceId: PropTypes.any,
  dateString: PropTypes.any,
  beaconMac: PropTypes.any,
  setTrackerItemClicked: PropTypes.func,
  employeeMapAssets: PropTypes.any,
  handleClickedMapItem: PropTypes.func
};
