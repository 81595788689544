import React from 'react';
import MapComponent from './MapComponent';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  rightBarWrapper: {
    display: 'flex',
    flex: '1',
    border: '1px solid grey'
  }
});

const MyAssetRightBar = ({ deviceId, dateString, beaconMac, handleClickedMapItem, employeeMapAssets }) => {
  const classes = useStyles();
  return (
    <div className={classes.rightBarWrapper}>
      <MapComponent
        deviceId={deviceId}
        dateString={dateString}
        beaconMac={beaconMac}
        handleClickedMapItem={handleClickedMapItem}
        employeeMapAssets={employeeMapAssets}
      />
    </div>
  );
};

export default MyAssetRightBar;

MyAssetRightBar.propTypes = {
  deviceId: PropTypes.any,
  dateString: PropTypes.any,
  beaconMac: PropTypes.any,
  handleClickedMapItem: PropTypes.func,
  employeeMapAssets: PropTypes.any
};
