import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },

  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  }
}));

const sleepModeOptions = [
  { value: 'disabled', label: 'Disabled', id: 0 },
  { value: 'sleep', label: 'Sleep', id: 1 },
  { value: 'deepSleep', label: 'Deep Sleep', id: 2 },
  { value: 'onlineDeepSleep', label: 'Online Deep Sleep', id: 3 },
  { value: 'ultraDeepSleep', label: 'Ultra Deep Sleep', id: 4 }
];

const SleepMode = ({ sleepModeRecord }) => {
  // eslint-disable-next-line no-console, no-undef
  console.log('sleepModeRecord', sleepModeRecord);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      timeout: 1,
      type: sleepModeRecord[0]?.current?.mode?.type,
      periodic_wakeup_checkbox: sleepModeRecord[0]?.current?.mode?.periodic_wakeup || false,
      periodic_wakeup: sleepModeRecord[0]?.current?.mode?.periodic_wakeup || 0,
      bluetooth_on: sleepModeRecord[0]?.current?.mode?.bluetooth_on || false,
      bluetooth_checkbox: sleepModeRecord[0]?.current?.mode?.bluetooth_on || false
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const typeValue = watch('type');
  const bluetooth_checkbox_value = watch('bluetooth_checkbox');
  // const periodic_wakeup = watch('periodic_wakeup');
  const periodic_wakeup_checkbox = watch('periodic_wakeup_checkbox');

  const handleSleepModeSubmit = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log(data);
    setLoading(true);

    let sleepModePayload;
    if (data.type !== 0) {
      sleepModePayload = {
        deviceId: sleepModeRecord[0]?.device_id,
        settSelector: 'sleep_mode',
        properties: {
          mode: {
            timeout: data.timeout,
            type: data.type,
            periodic_wakeup: data.periodic_wakeup,
            bluetooth_on: data.bluetooth_on
          }
        }
      };
    }
    if (data.type === 0) {
      sleepModePayload = {
        deviceId: sleepModeRecord[0]?.device_id,
        settSelector: 'sleep_mode',
        properties: {
          mode: {
            type: data.type
          }
        }
      };
    }

    flespiDeviceService
      .updateFlespiDeviceSetting(sleepModePayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated sleep mode settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update sleep mode settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleSleepModeSubmit)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Sleep Mode</Typography>
                <Typography className={classes.secondaryHeading}>
                  Device power saving mode setup. Modes vary in power consumption and functionality.
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Sleep Mode</Typography>

            <div style={{ marginBottom: '1rem' }}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={sleepModeOptions}
                    isSearchable
                    value={sleepModeOptions?.find((option) => option.id === field.value)}
                    onChange={(selected) => field.onChange(selected ? selected.id : null)}
                    classNamePrefix="select"
                    name="type"
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
              {errors.type && (
                <Typography color="error" style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>
                  {errors.type.message}
                </Typography>
              )}
            </div>

            {typeValue !== 0 && (
              <div>
                <div className={classes.accordionDetailsItem}>
                  <Controller
                    name="timeout"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Timeout"
                        name="timeout"
                        size="small"
                        type="number"
                        fullWidth
                        variant="outlined"
                        {...field}
                        error={!!errors.timeout}
                        helperText={errors.timeout?.message}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                        }}
                      />
                    )}
                  />
                </div>

                <div className={classes.accordionDetailsItem}>
                  {!periodic_wakeup_checkbox && <label> Periodically wakes up from sleep</label>}

                  {periodic_wakeup_checkbox && (
                    <Controller
                      name="periodic_wakeup"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label="Periodically wakes up from sleep"
                          name="periodic_wakeup"
                          size="small"
                          type="number"
                          fullWidth
                          variant="outlined"
                          {...field}
                          error={!!errors.periodic_wakeup}
                          helperText={errors.periodic_wakeup?.message}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                          }}
                        />
                      )}
                    />
                  )}

                  <Controller
                    name="periodic_wakeup_checkbox"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          name="periodic_wakeup_checkbox"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        {errors.activeStatus && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.activeStatus?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={classes.accordionDetailsItem}>
                  <div>
                    {bluetooth_checkbox_value && (
                      <Controller
                        control={control}
                        name="bluetooth_on"
                        render={({ field }) => (
                          <Switch
                            name="bluetooth_on"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            color="primary"
                          />
                        )}
                      />
                    )}
                    <label>Bluetooth will stay on in sleep mode</label>
                  </div>

                  <Controller
                    name="bluetooth_checkbox"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          name="bluetooth_checkbox"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        {errors.bluetooth_checkbox && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.bluetooth_checkbox?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            )}
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default SleepMode;

SleepMode.propTypes = {
  sleepModeRecord: PropTypes.any
};
