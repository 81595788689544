/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { PagingState, IntegratedPaging, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableColumnResizing, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import PropTypes from 'prop-types';
import { format } from 'date-fns';

import ImageFullScreenDialog from '../../../shared/dialog/ImageFullScreenDialog';

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};

const DateFormatter = ({ value }) => <span>{value ? format(new Date(value), 'dd/MM/yyyy') : null}</span>;

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;

const getRowId = (row) => row.id;

const MyAssetTable = ({ rows, columns }) => {
  const [myAssetColumnWidths, setMyAssetColumnWidths] = useState([
    { columnName: 'assetId', width: 180 },
    { columnName: 'tokenMac', width: 180 },
    { columnName: 'make', width: 180 },
    { columnName: 'model', width: 180 },
    { columnName: 'description', width: 180 },

    { columnName: 'tradeSpecific', width: 180 },
    { columnName: 'supplier', width: 180 },
    { columnName: 'category', width: 180 },
    { columnName: 'dateOfPurchase', width: 180 },
    { columnName: 'owner', width: 180 },

    { columnName: 'operator', width: 180 },
    { columnName: 'location', width: 180 },
    { columnName: 'locationDate', width: 180 },
    { columnName: 'insuranceName', width: 180 },
    { columnName: 'insurancePolicy', width: 180 },

    { columnName: 'insuranceExpiry', width: 180 },
    { columnName: 'maintenanceSchedule', width: 180 },
    { columnName: 'disposalDate', width: 180 },
    { columnName: 'image', width: 180 },
    { columnName: 'comments', width: 240 }
  ]);

  const [imageColumn] = useState(['image']);
  const [dateColumn] = useState(['dateOfPurchase', 'locationDate']);

  return (
    <div>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <ImageProvider for={imageColumn} />
          <DateTypeProvider for={dateColumn} />

          <PagingState defaultCurrentPage={0} pageSize={100} />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing columnWidths={myAssetColumnWidths} onColumnWidthsChange={setMyAssetColumnWidths} />
          <TableHeaderRow />
          <PagingPanel />
        </Grid>
      </Paper>
    </div>
  );
};

export default MyAssetTable;

MyAssetTable.propTypes = {
  rows: PropTypes.any,
  setRows: PropTypes.func
};
