import React, { useState } from 'react';
import VehicleTrackerLeftBar from './vehicleTrackerLeftBar/VehicleTrackerLeftBar';
import VehicleTrackerRightBar from './vehicleTrackerRightBar/VehicleTrackerRightBar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  vehicleTrackerContainer: {
    display: 'flex',
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  }
});

const VehicleTracker = () => {
  const classes = useStyles();

  const [trackerItemClicked, setTrackerItemClicked] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [vehicleTabClicked, setVehicleTabClicked] = useState('now');
  const [beaconMac, setBeaconMac] = useState(null);

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const [clickedDate, setClickedDate] = useState(todayDate);

  const [filter, setFilter] = useState({
    legendColor: 'All',
    searchInput: '',
    trackerState: 'All',
    filterAssetBy: null,
    skip: 0
  });

  const [searchInput, setSearchInput] = useState('');

  const handleClickedMapItem = (deviceName, deviceId, beaconMac) => {
    setTrackerItemClicked(true);
    setSearchInput(deviceName);
    setFilter({ ...filter, searchInput: deviceName });

    //for tracker assets
    if (deviceId) {
      setDeviceId(deviceId);
      setBeaconMac(null);
    }
    //for beacon assets
    if (beaconMac) {
      setBeaconMac(beaconMac);
      setDeviceId(null);
    }
  };

  return (
    <div className={classes.vehicleTrackerContainer}>
      <VehicleTrackerLeftBar
        trackerItemClicked={trackerItemClicked}
        setTrackerItemClicked={setTrackerItemClicked}
        deviceId={deviceId}
        setDeviceId={setDeviceId}
        setVehicleTabClicked={setVehicleTabClicked}
        clickedDate={clickedDate}
        setClickedDate={setClickedDate}
        beaconMac={beaconMac}
        setBeaconMac={setBeaconMac}
        handleClickedMapItem={handleClickedMapItem}
        filter={filter}
        setFilter={setFilter}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />

      <VehicleTrackerRightBar
        deviceId={deviceId}
        vehicleTabClicked={vehicleTabClicked}
        dateString={clickedDate}
        beaconMac={beaconMac}
        setTrackerItemClicked={setTrackerItemClicked}
        handleClickedMapItem={handleClickedMapItem}
      />
    </div>
  );
};

export default VehicleTracker;
