import React from 'react';
import PropTypes from 'prop-types';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import InboxIcon from '@material-ui/icons/Inbox';
import ClassIcon from '@material-ui/icons/Class';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import SideNavItem from './SideNavItem';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { List } from '@material-ui/core';

const userMenuItems = [
  { text: 'My Time', icon: <AccessTimeIcon />, path: '/mytime' },
  { text: 'My Expenses', icon: <AttachMoneyIcon />, path: '/myexpenses' },
  { text: 'Approve Timesheets', icon: <CheckCircleOutlineIcon />, path: '/approvetimesheets' },
  { text: 'My Asset', icon: <EmojiTransportationIcon />, path: '/myasset' },
  { text: 'My Asset Maps', icon: <LocationOnIcon />, path: '/myassetmaps' }
];

const adminMenuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'Timesheets and Logs', icon: <ListAltIcon />, path: '/timesheets', expandable: true },
  { text: 'Expenses', icon: <AttachMoneyIcon />, path: '/expenses' },
  { text: 'Users', icon: <PeopleAltIcon />, path: '/users' },
  { text: 'Customers', icon: <AccountBoxIcon />, path: '/customers' },
  { text: 'Suppliers', icon: <PeopleAltIcon />, path: '/suppliers' },
  { text: 'Item', icon: <InboxIcon />, path: '/item' },
  { text: 'Class', icon: <ClassIcon />, path: '/class' },
  { text: 'QboEmployee', icon: <LibraryAddIcon />, path: '/qboEmployee' },
  { text: 'Setup', icon: <SettingsIcon />, path: '/setup' },
  {
    text: 'Asset',
    icon: <EmojiTransportationIcon />,
    path: '/asset',
    expandable: true
  }
];

const SideNavList = ({ mobileOpen, role, selectedItem, handleListItemClick, handleLargeDevice, expandNestedItem }) => {
  return (
    <div>
      {role && role === 'User' && (
        <List>
          {userMenuItems.map(({ text, icon, path }) => (
            <SideNavItem
              key={text}
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText={text}
              icon={icon}
              to={path} // Pass `to` instead of wrapping in <Link> />}
            />
          ))}
        </List>
      )}

      {role && role === 'Admin' && (
        <List>
          {adminMenuItems.map(({ text, icon, path, expandable }) => (
            <SideNavItem
              key={text}
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText={text}
              icon={icon}
              expandNestedItem={expandable ? expandNestedItem : undefined}
              to={path} // Pass `to` instead of wrapping in <Link>
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default SideNavList;

SideNavList.propTypes = {
  mobileOpen: PropTypes.bool,
  handleListItemClick: PropTypes.func,
  role: PropTypes.string,
  selectedItem: PropTypes.any,
  handleLargeDevice: PropTypes.func,
  expandNestedItem: PropTypes.any
};
