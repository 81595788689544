import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import { Controller, useForm } from 'react-hook-form';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const batteryChargeModeOptions = [
  { value: 'onNeed', label: 'On need', id: 0 },
  { value: 'afterIgnitionOn', label: 'After ignition on', id: 1 },
  { value: 'always', label: 'Always', id: 2 }
];

const BatteryChargeMode = ({ batteryChargeModeRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      mode: batteryChargeModeRecord[0]?.current?.mode
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleBatteryChargeMode = (data) => {
    // eslint-disable-next-line no-console, no-undef
    console.log('data', data);

    setLoading(true);

    const ledDetectionPayload = {
      deviceId: batteryChargeModeRecord[0]?.device_id,
      settSelector: 'battery_charge_mode',
      properties: {
        mode: data.mode
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(ledDetectionPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated battery charge mode settings');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update battery charge mode settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleBatteryChargeMode)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Battery Charge Mode</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <div>
              <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Battery Charge Mode</Typography>

              <Controller
                control={control}
                name="mode"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue=""
                    isSearchable={true}
                    name="mode"
                    options={batteryChargeModeOptions}
                    value={batteryChargeModeOptions.find((option) => option.id === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>{' '}
      </form>
    </div>
  );
};

export default BatteryChargeMode;

BatteryChargeMode.propTypes = {
  batteryChargeModeRecord: PropTypes.array
};
