import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//to create the bill of the users/employers in quickbooks
function createBill(billPayload) {
  const endpoint = BASE_URL + 'bill/createBill';
  return axios.post(
    endpoint,
    {
      billPayload: billPayload
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

function updateBill(payload) {
  const endpoint = BASE_URL + 'bill/updateBill';
  return axios.put(endpoint, payload);
}

//create expense bill in qbo
function createExpenseBill(billPayload) {
  const endpoint = BASE_URL + 'expenses/createExpenseBill';
  return axios.post(
    endpoint,
    {
      billPayload: billPayload
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//fetch timesheet bill response logs
function getBillLogs() {
  const endpoint = BASE_URL + 'bill/getBillLogs';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const billService = {
  createBill,
  updateBill,
  createExpenseBill,
  getBillLogs
};

export default billService;
