import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MyAssetList from './MyAssetList';
import AssetMapPagination from '../../VehicleTracker/vehicleTrackerLeftBar/AssetMapPagination';
import { makeStyles } from '@material-ui/core';
import Route from './Route';

const useStyles = makeStyles({
  leftBarWrapper: {
    // border: '1px solid grey',
    borderRadius: '4px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginRight: '2rem'
  }
});

const MyAssetLeftBar = ({
  employeeMapAssets,
  handleClickedMapItem,
  setAssetItemClicked,
  assetItemClicked,
  clickedDate,
  deviceId,
  beaconMac,
  setClickedDate,
  selectedBeaconInstanceId,
  setDeviceId,
  setBeaconMac
}) => {
  const classes = useStyles();
  const [assetMapPage, setAssetMapPage] = useState(0);
  const [assetMapRowsPerPage, setAssetMapRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setAssetMapPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setAssetMapRowsPerPage(parseInt(event.target.value, 10));
    setAssetMapPage(0);
  };

  return (
    <div className={classes.leftBarWrapper}>
      {!assetItemClicked ? (
        <MyAssetList
          employeeMapAssets={employeeMapAssets}
          assetMapPage={assetMapPage}
          assetMapRowsPerPage={assetMapRowsPerPage}
          handleClickedMapItem={handleClickedMapItem}
        />
      ) : (
        <Route
          clickedDate={clickedDate}
          deviceId={deviceId}
          beaconMac={beaconMac}
          setClickedDate={setClickedDate}
          setAssetItemClicked={setAssetItemClicked}
          selectedBeaconInstanceId={selectedBeaconInstanceId}
          setDeviceId={setDeviceId}
          setBeaconMac={setBeaconMac}
        />
      )}
      <AssetMapPagination
        assetMapPage={assetMapPage}
        assetMapRowsPerPage={assetMapRowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        assetTrackerRecordsFromDb={employeeMapAssets}
      />
    </div>
  );
};

export default MyAssetLeftBar;

MyAssetLeftBar.propTypes = {
  employeeMapAssets: PropTypes.any,
  handleClickedMapItem: PropTypes.func,
  clickedDate: PropTypes.any,
  setAssetItemClicked: PropTypes.func,
  assetItemClicked: PropTypes.any,
  deviceId: PropTypes.any,
  beaconMac: PropTypes.any,
  setClickedDate: PropTypes.any,
  selectedBeaconInstanceId: PropTypes.any,
  setDeviceId: PropTypes.func,
  setBeaconMac: PropTypes.func
};
