import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const AuthorizedBeaconsList = ({ currentRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      id1: currentRecord[0]?.current?.id1,
      id2: currentRecord[0]?.current?.id2,
      id3: currentRecord[0]?.current?.id3,
      id4: currentRecord[0]?.current?.id4,
      id5: currentRecord[0]?.current?.id5,
      id6: currentRecord[0]?.current?.id6,
      id7: currentRecord[0]?.current?.id7,
      id8: currentRecord[0]?.current?.id8,
      id9: currentRecord[0]?.current?.id9,
      id10: currentRecord[0]?.current?.id10,
      id11: currentRecord[0]?.current?.id11,
      id12: currentRecord[0]?.current?.id12,
      id13: currentRecord[0]?.current?.id13,
      id14: currentRecord[0]?.current?.id14,
      id15: currentRecord[0]?.current?.id15,
      id16: currentRecord[0]?.current?.id16,
      id17: currentRecord[0]?.current?.id17,
      id18: currentRecord[0]?.current?.id18,
      id19: currentRecord[0]?.current?.id19,
      id20: currentRecord[0]?.current?.id20,
      id21: currentRecord[0]?.current?.id21,
      id22: currentRecord[0]?.current?.id22,
      id23: currentRecord[0]?.current?.id23,
      id24: currentRecord[0]?.current?.id24,
      id25: currentRecord[0]?.current?.id25
    }
  });

  const handleAuthorizedBeaconsList = (data) => {
    setLoading(true);

    let authorizedBeaconsListPayload = {
      deviceId: currentRecord[0]?.device_id,
      settSelector: 'auth_beacons',
      properties: {
        id1: data.id1,
        id2: data.id2,
        id3: data.id3,
        id4: data.id4,
        id5: data.id5,
        id6: data.id6,
        id7: data.id7,
        id8: data.id8,
        id9: data.id9,
        id10: data.id10,
        id11: data.id11,
        id12: data.id12,
        id13: data.id13,
        id14: data.id14,
        id15: data.id15,
        id16: data.id16,
        id17: data.id17,
        id18: data.id18,
        id19: data.id19,
        id20: data.id20,
        id21: data.id21,
        id22: data.id22,
        id23: data.id23,
        id24: data.id24,
        id25: data.id25
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(authorizedBeaconsListPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated authorized beacons list');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update authorized beacons list');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleAuthorizedBeaconsList)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>Authorized beacons list</Typography>
                <Typography className={classes.secondaryHeading}>
                  The list of Authorized BLE Beacons. iBeacon format supported (e.g.
                  00026da64fa24e988024bc5b71e0893e-1111-CCCC) and Eddystone (e.g.
                  00026da64fa24e988024bc5b-111111111111).
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>Authorized beacons list</Typography>
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id1"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 1"
                    name="id1"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id2"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 2"
                    name="id2"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id3"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 3"
                    name="id3"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id4"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 4"
                    name="id4"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id5"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 5"
                    name="id5"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id6"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 6"
                    name="id6"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id7"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 7"
                    name="id7"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id8"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 8"
                    name="id8"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id9"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 9"
                    name="id9"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id10"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 10"
                    name="id10"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id11"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 11"
                    name="id11"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id12"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 12"
                    name="id12"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id13"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 13"
                    name="id13"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id14"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 14"
                    name="id14"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>

            <div className={classes.accordionDetailsItem}>
              <Controller
                name="id15"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="BLE Beacon UID 15"
                    name="id15"
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default AuthorizedBeaconsList;

AuthorizedBeaconsList.propTypes = {
  currentRecord: PropTypes.array,
  settSelector: PropTypes.string,
  heading: PropTypes.string
};
