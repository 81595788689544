import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Switch,
  makeStyles,
  Divider,
  AccordionActions,
  Button
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const operandOptions = [
  { value: 'onExit', label: 'On Exit', id: 0 },
  { value: 'onEnter', label: 'On Enter', id: 1 },
  { value: 'onBoth', label: 'On Both', id: 2 },
  { value: 'monitoring', label: 'Monitoring', id: 3 },
  { value: 'onHysteresis', label: 'On Hysteresis', id: 4 },
  { value: 'onChange', label: 'On Change', id: 5 },
  { value: 'onDeltaChange', label: 'On Delta Change', id: 6 }
];

const prioOptions = [
  { value: 'disable', label: 'Disable', id: 0 },
  { value: 'low', label: 'Low', id: 1 },
  { value: 'high', label: 'High', id: 2 },
  { value: 'panic', label: 'Panic', id: 3 }
];

const VIN = ({ currentRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      event: currentRecord[0]?.current?.event,
      operand: currentRecord[0]?.current?.operand,
      prio: currentRecord[0]?.current?.prio
    }
  });

  const handleObdTabSettSelector = (data) => {
    setLoading(true);

    let numberOfDTCPayload = {
      deviceId: currentRecord[0]?.device_id,
      settSelector: 'obd_vin_io',
      properties: {
        event: data.event,
        operand: data.operand,
        prio: data.prio
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(numberOfDTCPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success(`Successfully updated fault codes settings`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || `Unable to update fault codes settings`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleObdTabSettSelector)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>VIN</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px' }}>VIN</Typography>

            {/* priority */}
            <div style={{ marginBottom: '0.5rem' }}>
              <Typography style={{ fontSize: '13px', color: '#757575' }}>Priority</Typography>
              <Controller
                control={control}
                name="prio"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={prioOptions.find((option) => option.id === field.value)}
                    isSearchable={true}
                    name="prio"
                    options={prioOptions}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
            </div>

            {/* operand */}
            <div>
              <Typography style={{ fontSize: '13px', color: '#757575' }}>Operand</Typography>
              <Controller
                control={control}
                name="operand"
                render={({ field }) => (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    value={operandOptions.find((option) => option.id === field.value)}
                    isSearchable={true}
                    name="operand"
                    options={operandOptions}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  />
                )}
              />
            </div>

            {/* event  */}
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <Controller
                control={control}
                name="event"
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                    name="event"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              />

              <Typography style={{ display: 'flex', alignItems: 'center' }}>Event Only</Typography>
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default VIN;

VIN.propTypes = {
  currentRecord: PropTypes.array
};
