import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import { ntpServerSchema } from '../helper/helper';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const NetworkTimeProtocolServer = ({ ntpServerRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [state] = useState({
    resync_period: ntpServerRecord[0]?.current?.resync_period || '',
    ntp_server1: ntpServerRecord[0]?.current?.ntp_server1 || '',
    ntp_server2: ntpServerRecord[0]?.current?.ntp_server2 || ''
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: state,
    resolver: zodResolver(ntpServerSchema),
    mode: 'onChange' // Enables validation on every change
  });

  const handleNTPServer = (data) => {
    setLoading(true);

    // eslint-disable-next-line no-console, no-undef
    const ntpServerPayload = {
      deviceId: ntpServerRecord[0]?.device_id,
      settSelector: 'ntp_server',
      properties: {
        resync_period: Number(data.resync_period),
        ntp_server1: data.ntp_server1,
        ntp_server2: data.ntp_server2
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(ntpServerPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success('Successfully updated motion detection source');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Unable to update settings');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleNTPServer)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}> Network Time Protocol Server</Typography>
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#9e9e9e ' }}>Network Time Protocol Server</Typography>

            <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
              <Controller
                name="resync_period"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.resync_period}
                    helperText={errors.resync_period?.message}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Hours</InputAdornment>
                    }}
                  />
                )}
              />

              <Controller
                name="ntp_server1"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.ntp_server1}
                    helperText={errors.ntp_server1?.message}
                    disabled
                  />
                )}
              />

              <Controller
                name="ntp_server2"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.ntp_server2}
                    helperText={errors.ntp_server2?.message}
                    disabled
                  />
                )}
              />
            </div>
          </AccordionDetails>
          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={Object.keys(errors).length > 0 || loading} // Corrected logic
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default NetworkTimeProtocolServer;

NetworkTimeProtocolServer.propTypes = {
  ntpServerRecord: PropTypes.array
};
