import React from 'react';
import UserTable from './table/UserTable';
import { Toaster } from 'react-hot-toast';
import useFetchEmployeeRecords from './hooks/useFetchEmployeeRecords';

const User = () => {
  // Fetch employee records based on the filter
  const { rows, setRows, employeeLoading, employeeError } = useFetchEmployeeRecords();

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />
      {employeeError && <div>Error: {employeeError}</div>}
      {employeeLoading ? <div>Loading...</div> : <UserTable rows={rows} setRows={setRows} />}
    </div>
  );
};

export default User;
