import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import CanTabSettSelector from './CanTabSettSelector';

const useStyles = makeStyles((theme) => ({
  canTab: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  }
}));

const CanTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.canTab}>
      <CanTabSettSelector
        currentRecord={selectedTabSettings.filter((record) => record.name === 'lvcanwindowsclose')}
        settSelector="lvcanwindowsclose"
        heading="Toggle CAN-CONTROL to close all windows"
        timeLabel="Closing time"
      />
      <CanTabSettSelector
        currentRecord={selectedTabSettings.filter((record) => record.name === 'lvcanwindowsopen')}
        settSelector="lvcanwindowsopen"
        heading="Toggle CAN-CONTROL to open all windows"
        timeLabel="Opening time"
      />
    </div>
  );
};

export default CanTab;

CanTab.propTypes = {
  selectedTabSettings: PropTypes.any
};
