import { format } from 'date-fns';
import * as z from 'zod';

/* 
format employeeList of Verd database
**/
export function getFormattedEmployeeList(employeeList) {
  return employeeList.map((item) => ({
    id: item._id,
    email: item.email,
    Phone: item.PrimaryPhone?.FreeFormNumber || item.PrimaryPhone || item.Mobile?.FreeFormNumber || '',
    userRole: item.type,
    DisplayName: item.FirstName && item.FamilyName ? item.FirstName + ' ' + item.FamilyName : item.DisplayName,

    FirstName: item.FirstName || '',
    FamilyName: item.FamilyName || '',
    MiddleName: item.MiddleName || '',
    Title: item.Title || item.title || '',
    PrimaryPhone: item.PrimaryPhone?.FreeFormNumber || item.PrimaryPhone || item.Mobile?.FreeFormNumber || '',
    Country: item.PrimaryAddr?.Country || '',
    CountrySubDivisionCode: item.PrimaryAddr?.CountrySubDivisionCode || '',
    PostalCode: item.PrimaryAddr?.PostalCode || '',
    Line1: item.PrimaryAddr?.Line1 || '',
    DateOfBirth: item.DateOfBirth ? format(new Date(item.DateOfBirth), 'yyyy-MM-dd') : '',
    AnniversaryDate: item.AnniversaryDate ? format(new Date(item.AnniversaryDate), 'yyyy-MM-dd') : '',
    bankAccount1_AccountName: item.bankAccount1_AccountName || '',
    bankAccount1_BSB: item.bankAccount1_BSB || '',
    bankAccount1_AccountNumber: item.bankAccount1_AccountNumber || '',
    superFund1_ProductCode: item.superFund1_ProductCode || '',
    superFund1_MemberNumber: item.superFund1_MemberNumber || '',
    superFund1_FundName: item.superFund1_FundName || '',
    emergencyContact1_Address: item.emergencyContact1_Address || '',
    emergencyContact1_ContactNumber: item.emergencyContact1_ContactNumber || '',
    emergencyContact1_Name: item.emergencyContact1_Name || '',
    emergencyContact1_Relationship: item.emergencyContact1_Relationship || '',
    KeyPayEmployeeId: item.KeyPayEmployeeId || '',
    HiredDate: item.HiredDate ? format(new Date(item.HiredDate), 'yyyy-MM-dd') : new Date(),
    ReleasedDate: item.ReleasedDate ? format(new Date(item.ReleasedDate), 'yyyy-MM-dd') : new Date(),
    selectedEmployeeId: undefined,
    jobTitle: item.jobTitle || '',
    keyPayEmployeeHourlyRate: item.rate || '',
    employeeType: item.type || 'User',
    portalAccess: item.portalAccess ? 'Active' : 'Inactive',

    //accounting
    vendorRef: item.vendorRef,
    itemRef: item.itemRef,
    taxType: item.taxType,
    hourRate: item.hourRate || ''
  }));
}

/* 
format keyPay employee according to Verd database and input fields in user dialog
**/
export function getFormattedEmployeeFromSelectedObj(selectedObj) {
  let keyPayUserDateOfBirth, keyPayUserAnniversaryDate;

  if (selectedObj.dateOfBirth) {
    keyPayUserDateOfBirth = format(new Date(selectedObj.dateOfBirth), 'yyyy-MM-dd');
  }

  if (selectedObj.anniversaryDate) {
    keyPayUserAnniversaryDate = format(new Date(selectedObj.anniversaryDate), 'yyyy-MM-dd');
  }

  return {
    FirstName: selectedObj.GivenName || selectedObj.firstName || selectedObj.FullyQualifiedName || '',
    FamilyName: selectedObj.FamilyName || selectedObj.surname || '',
    MiddleName: selectedObj.MiddleName || selectedObj.middleName || '',
    Title: selectedObj.Title || selectedObj.title || '',
    PrimaryPhone: selectedObj.PrimaryPhone?.FreeFormNumber || selectedObj.mobilePhone || selectedObj.Mobile,
    Country: selectedObj.BillAddr?.Country || selectedObj.residentialCountry || '',
    CountrySubDivisionCode: selectedObj.BillAddr?.CountrySubDivisionCode || selectedObj.residentialState || '',
    PostalCode: selectedObj.BillAddr?.PostalCode || selectedObj.residentialPostCode || '',
    Line1: selectedObj.BillAddr?.Line1 || selectedObj.residentialStreetAddress || '',
    email: selectedObj.PrimaryEmailAddr?.Address || selectedObj.emailAddress || '',
    DateOfBirth: selectedObj.DateOfBirth || keyPayUserDateOfBirth || null,
    AnniversaryDate: selectedObj.AnniversaryDate || keyPayUserAnniversaryDate || null,
    bankAccount1_AccountName: selectedObj.bankAccount1_AccountName || '',
    bankAccount1_BSB: selectedObj.bankAccount1_BSB || '',
    bankAccount1_AccountNumber: selectedObj.bankAccount1_AccountNumber || '',
    superFund1_ProductCode: selectedObj.superFund1_ProductCode || '',
    superFund1_MemberNumber: selectedObj.superFund1_MemberNumber || '',
    superFund1_FundName: selectedObj.superFund1_FundName || '',
    emergencyContact1_Address: selectedObj.emergencyContact1_Address || '',
    emergencyContact1_ContactNumber: selectedObj.emergencyContact1_ContactNumber || '',
    emergencyContact1_Name: selectedObj.emergencyContact1_Name || '',
    emergencyContact1_Relationship: selectedObj.emergencyContact1_Relationship || '',
    bankAccount1_AllocatedPercentage: selectedObj.bankAccount1_AllocatedPercentage,
    superFund1_AllocatedPercentage: selectedObj.superFund1_AllocatedPercentage,
    KeyPayEmployeeId: selectedObj.id || '',
    HiredDate: selectedObj.startDate || new Date(),
    ReleasedDate: selectedObj.endDate || new Date(),
    selectedEmployeeId: undefined,
    jobTitle: selectedObj.jobTitle || '',
    keyPayEmployeeHourlyRate: selectedObj.rate || ''
  };
}

/*
format selectedEmployee, chosen from keyPay/supplier and if edited use those edited inputs data
**/
export function getFormattedEmployeePayload(selectedEmployee, editedRow) {
  return {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token'),
    updateUserId: editedRow.id,
    FirstName: editedRow.FirstName || selectedEmployee.FirstName || '',
    FamilyName: editedRow.FamilyName || selectedEmployee.FamilyName || '',
    MiddleName: editedRow.MiddleName || selectedEmployee.MiddleName || '',
    Title: editedRow.Title || selectedEmployee.Title || '',
    PrimaryPhone: editedRow.PrimaryPhone || editedRow.Phone || selectedEmployee.PrimaryPhone,
    Country: editedRow.Country || selectedEmployee.Country || '',
    CountrySubDivisionCode: editedRow.CountrySubDivisionCode || selectedEmployee.CountrySubDivisionCode || '',
    PostalCode: editedRow.PostalCode || selectedEmployee.PostalCode || '',
    Line1: editedRow.Line1 || selectedEmployee.Line1 || '',
    email: editedRow.email || selectedEmployee.email || '',
    DateOfBirth: editedRow.DateOfBirth || selectedEmployee.DateOfBirth || null,
    AnniversaryDate: editedRow.AnniversaryDate || selectedEmployee.AnniversaryDate || null,
    bankAccount1_AccountName: selectedEmployee.bankAccount1_AccountName || '',
    bankAccount1_BSB: selectedEmployee.bankAccount1_BSB || '',
    bankAccount1_AccountNumber: selectedEmployee.bankAccount1_AccountNumber || '',
    superFund1_ProductCode: selectedEmployee.superFund1_ProductCode || '',
    superFund1_MemberNumber: selectedEmployee.superFund1_MemberNumber || '',
    superFund1_FundName: selectedEmployee.superFund1_FundName || '',
    emergencyContact1_Address: selectedEmployee.emergencyContact1_Address || '',
    emergencyContact1_ContactNumber: selectedEmployee.emergencyContact1_ContactNumber || '',
    emergencyContact1_Name: selectedEmployee.emergencyContact1_Name || '',
    emergencyContact1_Relationship: selectedEmployee.emergencyContact1_Relationship || '',
    bankAccount1_AllocatedPercentage: selectedEmployee.bankAccount1_AllocatedPercentage,
    superFund1_AllocatedPercentage: selectedEmployee.superFund1_AllocatedPercentage,
    KeyPayEmployeeId: selectedEmployee.KeyPayEmployeeId || '',
    HiredDate: selectedEmployee.startDate || new Date(),
    ReleasedDate: selectedEmployee.endDate || new Date(),
    selectedEmployeeId: editedRow.id || selectedEmployee.id || '',
    jobTitle: selectedEmployee.jobTitle || '',
    keyPayEmployeeHourlyRate: selectedEmployee.rate || ''
  };
}

/* 
if existing row/user's employee type is changed then use changed type before updating
**/
export function updateEmployeeType(selectedEmployeeType, editedRow) {
  return (editedRow = { ...editedRow, type: selectedEmployeeType.value });
}

/* 
if approval required and approverId is changed update editedRow
**/
export function updateEmployeeApprovalDetails(selectedApprover, approvalRequired, editedRow) {
  return (editedRow = { ...editedRow, approverId: selectedApprover?.id, approvalRequired: approvalRequired });
}

export function isLinkEmployeeValid(linkEmployeePayload) {
  if (
    linkEmployeePayload.employeeId &&
    linkEmployeePayload.itemRef &&
    linkEmployeePayload.vendorRef &&
    linkEmployeePayload.taxType &&
    linkEmployeePayload.hourRate
  )
    return true;
  return false;
}

export function isSupplierBillValid(supplierChecked) {
  if (supplierChecked) return true;
  return false;
}

export function isSuperAnnuationValid(superChecked, superPercentage, superAccount, superPayableAccount) {
  if (superChecked) {
    if (
      superPercentage &&
      superAccount?.value &&
      superAccount?.id &&
      superPayableAccount?.value &&
      superPayableAccount?.id
    )
      return true;
  }

  if (!superChecked)
    if (
      !superPercentage &&
      !superAccount?.value &&
      !superAccount?.id &&
      !superPayableAccount?.value &&
      !superPayableAccount?.id
    ) {
      return true;
    }

  return false;
}

export function formatUserUniform(row, employeeUniform) {
  const uniformRecord = {
    shirtSize: employeeUniform.shirtSize,
    jacketSize: employeeUniform.jacketSize,
    jumperSize: employeeUniform.jumperSize,
    employeeId: row.id
  };
  return uniformRecord;
}

export const employeeUniformSchema = z.object({
  shirtSize: z.string().min(1, { message: 'Shirt size is required' }),
  jumperSize: z.string().min(1, { message: 'Jumper size is required' }),
  jacketSize: z.string().min(1, { message: 'Jacket size is required' }),
  employeeId: z.string().or(z.literal(''))
});
