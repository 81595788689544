export function getFormattedBeacons(beacons) {
  return beacons.map((beacon) => ({
    beaconDBId: beacon._id,
    mac: beacon?.mac || null,
    InstanceID: beacon?.InstanceID || null,
    NamespaceID: beacon?.NamespaceID || null,
    name: beacon?.name || null,
    power: beacon?.power || null,
    position: beacon?.position || null,
    location: beacon?.location || null,
    rssi: beacon?.rssi || null,
    UUID: beacon?.UUID || null,
    protocol: beacon?.protocol || null,
    timestamp: beacon?.timestamp,
    createdAt: beacon?.createdAt,
    updatedAt: beacon?.updatedAt
  }));
}
