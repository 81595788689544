import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsItem: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));

const GetverCommandResponse = ({ getverRecord }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordionSummary}
        >
          <div className={classes.accordionSummaryDiv}>
            <div className={classes.settingsDiv}>
              <SettingsIcon />
            </div>

            <div>
              <Typography className={classes.heading}> getver command response </Typography>
              <Typography className={classes.secondaryHeading}>
                Readonly settings to check device firmware and hardware info
              </Typography>
            </div>
          </div>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={classes.accordionDetails}>
          <Typography style={{ color: '#9e9e9e ', marginBottom: '10px' }}>getver command response</Typography>

          <div className={classes.accordionDetailsItem}>
            <TextField
              label="FirmWare Version"
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? getverRecord[0]?.current?.firmware_version : ''}
              className={classes.textField}
              autoFocus
              id="firmware_version"
              name="firmware_version"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="GPS chip version"
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? getverRecord[0]?.current?.gps_chip : ''}
              className={classes.textField}
              autoFocus
              id="gps_chip"
              name="gps_chip"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="Hardware model"
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? getverRecord[0]?.current?.hardware_model : ''}
              className={classes.textField}
              autoFocus
              id="hardware_model"
              name="hardware_model"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="IMEI"
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? getverRecord[0]?.current?.imei : ''}
              className={classes.textField}
              autoFocus
              id="imei"
              name="imei"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />

            <TextField
              label="Init time"
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? format(new Date(getverRecord[0]?.current?.init), 'dd/MM/yyyy') : ''}
              className={classes.textField}
              autoFocus
              id="init"
              name="init"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />
            <TextField
              label="Uptime in seconds "
              variant="outlined"
              size="small"
              value={getverRecord.length > 0 ? getverRecord[0]?.current?.uptime : ''}
              className={classes.textField}
              autoFocus
              id="uptime"
              name="uptime"
              type="text"
              style={{ marginBottom: '1rem' }}
              disabled
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GetverCommandResponse;

GetverCommandResponse.propTypes = {
  getverRecord: PropTypes.array
};
