import { useEffect, useState } from 'react';
import assetService from '../../../services/AssetService';
import { getFormattedAssetWithTokenMac } from '../../VehicleTracker/helper/helper';
import { subHours } from 'date-fns';

//data should lag 12hours
export default function useFetchEmployeeMapAssetRecord() {
  const [employeeMapAssets, setEmployeeMapAssets] = useState([]);
  const [employeeMapAssetsError, setEmployeeMapAssetError] = useState(null);
  const [employeeMapAssetLoading, setEmployeeMapAssetLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeAssetMapRecord = async () => {
      try {
        setEmployeeMapAssetLoading(true);
        setEmployeeMapAssetError(null);

        // eslint-disable-next-line no-undef
        const employeeDisplayName = localStorage.getItem('name');
        const assetPayload = {
          employeeDisplayName: employeeDisplayName,
          twelveHoursLagTimestamp: twelveHoursAgo()
        };

        const assetResponse = await assetService.getEmployeeMapAssets(assetPayload);
        const assetWithTokenMac = assetResponse?.data?.data || [];

        const assetFormattedRecords = getFormattedAssetWithTokenMac(assetWithTokenMac);
        setEmployeeMapAssets(assetFormattedRecords);
      } catch (e) {
        setEmployeeMapAssetError(e.message || 'Could not fetch asset records');
      } finally {
        setEmployeeMapAssetLoading(false);
      }
    };

    fetchEmployeeAssetMapRecord();
  }, []);

  return { employeeMapAssets, setEmployeeMapAssets, employeeMapAssetLoading, employeeMapAssetsError };
}

function twelveHoursAgo() {
  // Get the current timestamp
  const now = new Date();
  const twelveHoursAgo = subHours(now, 12);

  //UNIX timestamp in seconds
  const twelveHoursAgoTimestamp = Math.floor(twelveHoursAgo.getTime() / 1000);

  return twelveHoursAgoTimestamp;
}
