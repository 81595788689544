import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Divider,
  AccordionActions,
  Button,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import flespiDeviceService from '../../../../services/FlespiDeviceService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#757575',
    fontWeight: '700'
  },
  accordionSummaryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  settingsDiv: {
    marginRight: '12px'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const CanTabSettSelector = ({ currentRecord, settSelector, heading, timeLabel }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      time: currentRecord[0]?.current?.time
    }
  });

  const handleObdTabSettSelector = (data) => {
    setLoading(true);

    let numberOfDTCPayload = {
      deviceId: currentRecord[0]?.device_id,
      settSelector: settSelector,
      properties: {
        time: data.time
      }
    };

    flespiDeviceService
      .updateFlespiDeviceSetting(numberOfDTCPayload)
      .then((data) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('data is:', data);
        toast.success(`Successfully updated ${heading} settings`);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || `Unable to update ${heading} settings`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // eslint-disable-next-line no-console, no-undef
  console.log('errors', errors);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <form onSubmit={handleSubmit(handleObdTabSettSelector)}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordionSummary}
          >
            <div className={classes.accordionSummaryDiv}>
              <div className={classes.settingsDiv}>
                <SettingsIcon />
              </div>

              <div>
                <Typography className={classes.heading}>{heading}</Typography>
              </div>
            </div>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography style={{ color: '#9e9e9e', marginBottom: '10px', fontSize: '14px' }}>{heading}</Typography>

            {/* time */}
            <div className={classes.accordionDetailsItem}>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={timeLabel}
                    name="time"
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errors.time}
                    helperText={errors.time?.message}
                  />
                )}
              />
            </div>
          </AccordionDetails>

          <AccordionActions style={{ cursor: 'pointer' }}>
            <Button
              size="small"
              color="primary"
              title="Changes will apply to the device"
              type="submit"
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </AccordionActions>
        </Accordion>
      </form>
    </div>
  );
};

export default CanTabSettSelector;

CanTabSettSelector.propTypes = {
  currentRecord: PropTypes.array,
  settSelector: PropTypes.string,
  heading: PropTypes.string,
  timeLabel: PropTypes.string
};
