import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Avatar from '@material-ui/core/Avatar';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  dateContent: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const TrackerCard = ({ trackerItem, handleBackButton }) => {
  const classes = useStyles();

  if (!trackerItem.length) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography color="textSecondary">No tracker data available</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <div className={classes.contentWrapper}>
          {/* Back Button */}
          <Button
            onClick={handleBackButton}
            color="default"
            variant="contained"
            className={classes.backButton}
            aria-label="Go back"
          >
            <KeyboardBackspaceIcon />
          </Button>

          {/* Device Info */}
          <div className={classes.infoContainer}>
            <Typography className={classes.title} color="primary">
              {trackerItem[0].deviceName}
            </Typography>

            <div className={classes.avatarWrapper}>
              <Avatar className={classes.avatar}>
                <DeveloperBoardIcon />
              </Avatar>

              <Typography variant="body1" color="textSecondary">
                {trackerItem[0].ident}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.dateContent}>
          <Typography variant="body1" color="textSecondary">
            {formatDistanceToNow(new Date(trackerItem[0].timestamp * 1000), { addSuffix: true })}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TrackerCard.propTypes = {
  trackerItem: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string.isRequired,
      ident: PropTypes.string.isRequired,
      timestamp: PropTypes.any
    })
  ),
  handleBackButton: PropTypes.func.isRequired
};

export default TrackerCard;
