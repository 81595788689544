import { useEffect, useState } from 'react';
import { getFormattedEmployeeList } from '../helper/helper';
import employeeService from '../../../services/EmployeeService';

export default function useFetchEmployeeRecords() {
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [employeeError, setEmployeeError] = useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchEmployeeRecords = async () => {
      try {
        setEmployeeLoading(true);

        const employeeRecords = await employeeService.getEmployees();

        const formattedEmployeeList = getFormattedEmployeeList(employeeRecords.data.data);
        // eslint-disable-next-line no-console, no-undef
        console.log('formattedEmployeeList', formattedEmployeeList);

        // const filteredRows = formattedEmployeeList.filter((employee) => {
        //   if (filter.userList === 'All Users') {
        //     return true;
        //   }

        //   if (filter.userList === 'Active Users' && employee.portalAccess === true) {
        //     return true;
        //   }

        //   return false;
        // });

        setRows(formattedEmployeeList);
      } catch (error) {
        setEmployeeError(error?.message || 'Could not fetch employee records');
      } finally {
        setEmployeeLoading(false);
      }
    };
    fetchEmployeeRecords();
  }, [setRows]);

  return { rows, setRows, employeeLoading, employeeError };
}
