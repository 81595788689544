import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import BluetoothWorkMode from './BluetoothWorkMode';
import BLECommonSettings from './BLECommonSettings';
import AutoConnectToExternalDevice from './AutoConnectToExternalDevice';
import AuthorizedBeaconsList from './AuthorizedBeaconsList';
import BeaconSettings from './BeaconSettings';

const useStyles = makeStyles((theme) => ({
  systemParamsTab: {
    display: 'flex',
    gap: theme.spacing(3),
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  systemParamsTabDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  }
}));

const BluetoothTab = ({ selectedTabSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.systemParamsTab}>
      <div className={classes.systemParamsTabDiv}>
        <BluetoothWorkMode currentRecord={selectedTabSettings.filter((record) => record.name === 'bluetooth')} />

        <BLECommonSettings currentRecord={selectedTabSettings.filter((record) => record.name === 'ble_common')} />
      </div>

      <div className={classes.systemParamsTabDiv}>
        <AutoConnectToExternalDevice
          currentRecord={selectedTabSettings.filter((record) => record.name === 'bluetooth_auto_connect')}
        />

        <AuthorizedBeaconsList currentRecord={selectedTabSettings.filter((record) => record.name === 'auth_beacons')} />
      </div>

      <div className={classes.systemParamsTabDiv}>
        <BeaconSettings currentRecord={selectedTabSettings.filter((record) => record.name === 'beacon')} />
      </div>
    </div>
  );
};

export default BluetoothTab;

BluetoothTab.propTypes = {
  selectedTabSettings: PropTypes.any
};
